import { render, screen } from "@testing-library/react";
import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import Carousel from "react-bootstrap/Carousel";

import Header from "../component/header";
import Container from "react-bootstrap/Container";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import Accordion from "react-bootstrap/Accordion";

import Tab from "react-bootstrap/Tab";
import Tabs from "react-bootstrap/Tabs";

import home from "../img/home.png";
import Cricket from "../img/cricket.png";
import Tennis from "../img/tennis.png";
import Basketball from "../img/basketball.png";
import Soccer from "../img/soccer.png";
import Golf from "../img/golf.png";
import Politics from "../img/poltcs.png";
import Baseball from "../img/baseball.png";
import lbanner from "../img/left-banner.png";
import IndFlag from "../img/flag-india.png";
import PakFlag from "../img/flag-Pak.png";
import VS from "../img/vs.png";
import UpcomingDark from "../img/upcoming-dark.png";
import live from "../img/live.png";
import BatBlue from "../img/bat-blue.png";
import trophy from "../img/trophy.png";
import telecase from "../img/telecast.png";
import MBanner from "../img/middle-banner.png";
import upcoming from "../img/upcoming.png";
import InPlayDark from "../img/inplay-dark.png";
import Racket from "../img/racket.png";
import RightBanner from "../img/right-banner.png";
import RightBanner1 from "../img/right-banner-1.png";
import Dice from "../img/dise.png";
import Whatsapp from "../assets/images/whatapp.svg";
import VSWhite from "../img/vs-white.png";
import BetSlipContext from "../context/BetSlipContext";
import { useState, useEffect, useContext } from "react";
import { isEmpty } from "lodash";
import { apiGet } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import ListPage from "./Home/ListPage";
import Home from "./home";
import PlaceBet from "../component/PlaceBet";
import OpenBets from "../component/OpenBets";
import AuthContext from "../context/AuthContext";
import loading from "../img/loading_bet.webp";
import CalendarComponent from "../component/Calendar";
import AfterLoginImage from "../assets/images/loginBanner-new.jpg";
import { TiDelete } from "react-icons/ti";
import { Modal } from "react-bootstrap";
import { useTranslation } from "react-i18next";

import English from "../img/icons/united-kingdom.png";
import Arabic from "../img/icons/united-arab-emirates.png";
import Kannad from "../img/icons/kannad.png";
import Hindi from "../img/icons/square.png";
import Spanish from "../img/icons/flag.png";
import Select, { components } from "react-select";
import Loader from "../component/Loader";
import EvolutionModal from "../component/EvolutionModal";
import Setting from "../component/Setting";
function Layout({ children }) {
  const params = useParams();
  const { t } = useTranslation();
  const location = useLocation();
  const [tournamentData, setTournamentData] = useState([]);
  const navigate = useNavigate();
  const {
    rightTab,
    setRightTab,
    showDate,
    totalCount,
    user,
    afterLogin,
    setAfterLogin,
    profileData,
    languageShow,
    setLanguageShow,
    changeLanguage,
    evolutionModal,
    setEvolutionModal,setting
  } = useContext(AuthContext);
  const { betSlipObject, betLoader, resetBetPosition } =
    useContext(BetSlipContext);
  const sortBy = (array) => {
    return array?.sort(function (a, b) {
      return new Date(b.createdAt) - new Date(a.createdAt);
    });
  };

  const getTournamentData = async () => {
    const { status, data: response_users } = await apiGet(apiPath.seriesList);
    if (status === 200) {
      if (response_users.success) {
        setTournamentData({
          cricket:
            response_users.results?.filter((res) => {
              return res?.gameType == "cricket";
            }) || [],
          tennis:
            response_users.results?.filter((res) => {
              return res?.gameType == "tennis";
            }) || [],
          soccer:
            response_users.results?.filter((res) => {
              return res?.gameType == "soccer";
            }) || [],
          all: sortBy(response_users.results),
        });
      }
    }
  };

  useEffect(() => {
    getTournamentData();
  }, []);

  const [data, setData] = useState([]);

  const getCurrentBets = async () => {
    const { status, data: response_users } = await apiGet(
      apiPath.mobileCurrentBets
    );
    // getBetsData
    if (status === 200) {
      if (response_users.success) {
        setData(response_users.results);
      }
    }
  };

  useEffect(() => {
    if (rightTab == "openBet" && !isEmpty(user)) {
      getCurrentBets();
    }
  }, [rightTab, user]);

  const countries = [
    { value: "en", label: "English", icon: English },
    { value: "ka", label: "Kannad", icon: Kannad },
    { value: "ar", label: "Arabic", icon: Arabic },
    { value: "sp", label: "Spanish", icon: Spanish },
    { value: "hi", label: "Hindi", icon: Hindi },
  ];
  const Option = (props) => (
    <components.Option
      {...props}
      className="country-option"
      style={{ padding: "3px 10px", marginBottom: "10px" }}
    >
      <img
        style={{ width: "20px", marginRight: "10px" }}
        src={props.data.icon}
        alt="logo"
        className="country-logo"
      />
      {props.data.label}
    </components.Option>
  );
  const SingleValue = ({ children, ...props }) => (
    <components.SingleValue {...props}>
      <img
        style={{ width: "20px", marginRight: "10px" }}
        src={
          isEmpty(localStorage.getItem("language_dir"))
            ? English
            : countries?.find((res) => {
                return res?.value == localStorage.getItem("language_dir");
              })?.icon
        }
        alt="s-logo"
        className="selected-logo"
      />
      {children}
    </components.SingleValue>
  );

  let find =
    profileData?.banners?.find((res) => {
      return res?.type == "home_popup";
    }) || {};

  useEffect(() => {
    if (!["match"].includes(location?.pathname?.split("/")[1])) {
      setRightTab("openBet");
    }
  }, [location]);
  return (
    <div style={betLoader ? { cursor: "not-allowed" } : {}}>
      {" "}
      {languageShow && (
        <Modal centered show={languageShow}>
          <Modal.Body className="position-relative p-0">
            <div className="d-flex flex-column align-items-start p-3">
              <span className="mb-2">Select Language</span>
              <Select
                className="w-100"
                value={
                  isEmpty(localStorage.getItem("language_dir"))
                    ? English
                    : countries?.find((res) => {
                        return (
                          res?.value == localStorage.getItem("language_dir")
                        );
                      })
                }
                options={countries}
                onChange={(e) => changeLanguage(e.value)}
                styles={{
                  singleValue: (base) => ({
                    ...base,
                    display: "flex",
                    alignItems: "center",
                  }),
                }}
                components={{ Option, SingleValue }}
              />
            </div>
          </Modal.Body>
        </Modal>
      )}
      {setting && <Setting/>}
      {afterLogin && (
        <Modal
          size="xl"
          centered
          show={afterLogin}
          onHide={() => setAfterLogin(false)}
        >
          <Modal.Body className="position-relative p-0">
            <TiDelete
              onClick={() => setAfterLogin(false)}
              color="white"
              style={{ position: "absolute", right: "-1%", top: "-2%" }}
              size={30}
            />
            <img
              style={{ width: "100%" }}
              src={
                !isEmpty(find)
                  ? `${process.env.REACT_APP_API_BASE_URL}${find?.banner_path}`
                  : AfterLoginImage
              }
            />
          </Modal.Body>
        </Modal>
      )}
      {!["match", "customer-support"].includes(
        location?.pathname?.split("/")[1]
      ) && (
        <div>
          <img
            src={Whatsapp}
            onClick={() => {
              // if (isEmpty(user)) {
              //   navigate("/login");
              // } else {
              //   navigate("/customer-support");
              // }
              window.open(
                "https://api.whatsapp.com/send?phone=447446136999"
              )
            }}
            style={{
              position: "fixed",
              bottom: "5%",
              left: "5%",
              zIndex: "99999999999999999",
            }}
          />
          {/* {totalCount > 0 && (
            <span
              style={{
                position: "fixed",
                bottom: "10.5%",
                left: "7.5%",
                zIndex: "99999999999999999",
                background: "red",
                color: "white",
                borderRadius: "50%",
                padding: "0 4px",
              }}
            >
              {totalCount}
            </span>
          )} */}
        </div>
      )}
      {betLoader && (
        <div className="loading-bet">
          <img src={loading} />
        </div>
      )}
      <>
        <Header />
        <div className="d-flex w-100">
          <div className="left-menu">
            <div className="LeftTabs">
              <Tabs
                fill
                defaultActiveKey="betslip"
                id="uncontrolled-tab-example"
              >
                <Tab eventKey="betslip" title={"Popular"}>
                  <Accordion activeKey={["0", "1", "2"]}>
                    <Accordion.Item eventKey="0">
                      <Accordion.Header>{t("Cricket")}</Accordion.Header>
                      {tournamentData?.cricket?.length > 0 && (
                        <Accordion.Body>
                          <ul>
                            {tournamentData?.cricket?.map((res) => {
                              return (
                                <li>
                                  <Link to={`/${res?.gameType}`}>
                                    {res?.seriesName}
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </Accordion.Body>
                      )}
                    </Accordion.Item>
                    <Accordion.Item eventKey="1">
                      <Accordion.Header>{t("Soccer")}</Accordion.Header>
                      {tournamentData?.soccer?.length > 0 && (
                        <Accordion.Body>
                          <ul>
                            {tournamentData?.soccer?.map((res) => {
                              return (
                                <li>
                                  <Link to={`/${res?.gameType}`}>
                                    {res?.seriesName}
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </Accordion.Body>
                      )}
                    </Accordion.Item>
                    <Accordion.Item eventKey="2">
                      <Accordion.Header>{t("Tennis")}</Accordion.Header>
                      {tournamentData?.tennis?.length > 0 && (
                        <Accordion.Body>
                          <ul>
                            {tournamentData?.tennis?.map((res) => {
                              return (
                                <li>
                                  <Link to={`/${res?.gameType}`}>
                                    {res?.seriesName}
                                  </Link>
                                </li>
                              );
                            })}
                          </ul>
                        </Accordion.Body>
                      )}
                    </Accordion.Item>
                  </Accordion>
                </Tab>
              </Tabs>
            </div>
          </div>

          <div className="right-con pb-0">
            <Row className="m-0">
              {children}

              {!["casino", "deposit", "withdraw"].includes(
                location?.pathname?.split("/")[1]
              ) && (
                <Col md={3} className="p-0">
                  <div className="lb-box mb-1">
                    <Tabs
                      // id="uncontrolled-tab-example"
                      // className="mb-2"
                      style={{ borderBottom: "1px solid #F1F1FF" }}
                      fill
                      activeKey={rightTab}
                      onSelect={(e) => setRightTab(e)}
                    >
                      {["match"].includes(
                        location?.pathname?.split("/")[1]
                      ) && (
                        <Tab eventKey="betslip" title={t("Bet_Slip")}>
                          {!isEmpty(betSlipObject?.teamName) &&
                          !isEmpty(betSlipObject?.betType) &&
                          ["betFair", "multi"].includes(
                            betSlipObject?.playType
                          ) ? (
                            <PlaceBet />
                          ) : (
                            <div
                              style={{
                                // borderBottom: "1px solid #ddd",
                                // background: "black",
                                padding: "8px 0",
                                // fontWeight: "600",
                                fontSize: "12px",
                                color: "black",
                                display: "flex",
                                justifyContent: "center",
                                fontSize: "13px",
                              }}
                              // className="laegueRow"
                            >
                              No BetSlip Found
                            </div>
                          )}
                        </Tab>
                      )}
                      <Tab eventKey="openBet" title={"Market Bets"}>
                        <OpenBets data={data} />
                      </Tab>
                    </Tabs>
                  </div>
                </Col>
              )}
            </Row>
          </div>
        </div>
      </>
      {evolutionModal?.status && (
        <EvolutionModal
          show={evolutionModal?.status}
          handelClose={() => setEvolutionModal({ data: {}, status: false })}
        />
      )}
      {showDate?.status && !isEmpty(showDate?.type) && <CalendarComponent />}
    </div>
  );
}

export default Layout;

{
  /* <div className="btmBox mb-0 text-center fs-12 p-3">
              &copy; {t("copyright")}
            </div> */
}
const casinoList = [
  {
    title: "Indian Poker",
    key: "poker",
    key: "Indian_Poker",
  },
  {
    title: "Royal Gamming",
    key: "Royal Gamming",
  },
  {
    title: "Aviator",
    key: "Aviator",
    key: "Aviator",
  },
  {
    title: "Live",
    key: "Live",
    key: "Live",
  },
  {
    title: "Table",
    key: "Table",
    key: "Table",
  },
  {
    title: "Slot",
    key: "Slot",
    key: "Slot",
  },
  {
    title: "Dice",
    key: "Dice",
    key: "Dice",
  },
  {
    title: "Fishing",
    key: "Fishing",
    key: "Fishing",
  },
  {
    title: "Egame",
    key: "Egame",
    key: "Egame",
  },
  {
    title: "AWC",
    key: "AWC",
    key: "AWC",
  },
];
