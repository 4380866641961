import { useNavigate, Link, useLocation } from "react-router-dom";
import React, { useContext, useEffect, useState, useRef } from "react";
import {
  Form,
  Button,
  InputGroup,
  ButtonGroup,
  Container,
} from "react-bootstrap";
import { Controller, useForm } from "react-hook-form";
import { inRange, isEmpty } from "lodash";
import { apiGet, apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import AuthContext from "../context/AuthContext";
import Modal from "react-bootstrap/Modal";
import PhoneInput from "react-phone-input-2";
import ConfirmPin from "../component/ConfirmPin";
import "./Deposit.css";
import SidebarLayout from "../component/SidebarLayout";
import { toast } from "react-toastify";
import { useTranslation } from "react-i18next";
const WithDraw = () => {
  const navigate = useNavigate();
  let location = useLocation();
  const { t } = useTranslation();
  const {
    register,
    handleSubmit,
    reset,
    setValue,
    watch,
    control,
    formState: { errors },
  } = useForm({
    mode: "onChange",
    shouldFocusError: true,
  });
  const {
    userCoins,
    amounutRefresh,
    user,
    sendMobileOTP,
    getProfileData,
    profileData,
    setSeconds,
    setMinutes,
    seconds,
    minutes,
    getSendOtpStatus,
    setSendOtpStatus,
  } = useContext(AuthContext);
  const [checkbox, setCheckBox] = useState(false);
  const [otpSend, setOtpSend] = useState(false);
  const [bank, setBank] = useState([]);
  const [confirmPin, setConfirmPin] = useState(false);
  const [pin, setPin] = useState("");
  const [afterData, setAfterData] = useState({});
  const [verifyEdit, setVerifyEdit] = useState({});
  const [verify, setVerify] = useState({});
  const handleCloseVerify = () => {
    setVerifyEdit({ status: false });
  };
  const onSubmit = async (body) => {
    // setConfirmPin(true);
    setAfterData(body);
  };

  const getOTP = () => {};
  const AfterPinSubmit = async (body) => {
    if (checkbox) {
      setLoader(true);
      body = {
        amount: body?.amount,
        bank: body?.bank,
        ifsc_code: body?.ifsc_code,
        AccountName: body?.accountName,
        BankAccount: body?.accountNumber,
        phone_number: body?.mobile,
        otp: body?.verificationCode,
        pin: body?.password,
      };

      const { status, data } = await apiPost(apiPath.withdrawal, body);
      if (status == 200) {
        if (data?.success) {
          amounutRefresh();
          reset();
          setAfterData({});
          setOtpSend(false);
          setPin("");
          setCheckBox(false);
          setConfirmPin(false);
          setLoader(false);
          toast.success(data?.message);
        } else {
          reset();
          setOtpSend(false);
          setCheckBox(false);
          setLoader(false);
          toast.error(data?.message);
        }
      } else {
        reset();
        setOtpSend(false);
        setCheckBox(false);
        setLoader(false);
        toast.error(data?.message);
      }
    } else {
      toast.error("Please Select Terms and Condition");
    }
  };

  const getBank = async () => {
    const { status, data } = await apiPost(apiPath.getBank, {
      type: "withdrawal",
    });
    if (status == 200) {
      if (data?.success) {
        setBank(data?.results?.bankList);
      }
    }
  };

  const userSendOtp = async (value) => {
    const { status, data } = await apiPost(apiPath.sendOtp, {
      phone_number: value,
    });
    if (status == 200) {
      if (data?.success) {
        setOtpSend(true);
      }
    }
  };
  const getBankDetail = async () => {
    const { status, data } = await apiPost(apiPath.getBankDetail);
    if (status == 200) {
      if (data?.success) {
        setValue("ifsc_code", data?.results?.ifsc_code);
        setValue("accountName", data?.results?.AccountName);
        setValue("accountNumber", data?.results?.BankAccount);
      }
    }
  };

  useEffect(() => {
    getBank();
    getProfileData();
  }, []);

  const inputRef = useRef(null);

  const [isLoader1, setLoader1] = useState(false);
  const {
    register: register1,
    handleSubmit: handleSubmit1,
    control: control1,
    formState: { errors: errors1 },
    reset: reset1,
  } = useForm({});

  const [isLoader, setLoader] = useState(false);
  const onSubmit1 = async (body) => {
    setLoader(true);
    const { status, data } = await apiPost(apiPath.verifyOtp, {
      phone_number:
        inputRef?.current?.state.selectedCountry?.countryCode +
        body?.mobile?.substring(
          inputRef?.current?.state.selectedCountry?.countryCode?.length,
          body?.mobile?.toString()?.length
        ),
      otp: body?.verificationCode,
    });
    if (status === 200) {
      if (data.success) {
        setLoader(false);
        handleCloseVerify();
        getProfileData();
        reset();
        setSendOtpStatus(false);
      } else {
        setLoader(false);
      }
    } else {
      setLoader(false);
    }
  };

  return (
    <SidebarLayout heading={t("Withdraw")}>
      <div
        // style={{ marginTop: "10px" }}
        className="main dw-p withdraw-box page-content-box w-100 bg-gradual-black"
      >
        <div className="width70">
          <div
            style={{
              background: "white",
              padding: "10px",
              borderRadius: "5px",
              marginBottom: "10px",
            }}
          >
            <div className="d-flex flex-column align-items-start ">
              <span style={{ fontWeight: "600", fontSize: "16px" }}>
                Withdrawal Amount
              </span>
              <span
                style={{
                  fontSize: "1.2rem",
                  color: "black",
                }}
              >
                INR{" "}
                <span style={{ fontWeight: "600", paddingLeft: "4px" }}>
                  {(userCoins?.balance?.toFixed(2) -
                    profileData?.availableTurnoverFinal?.toFixed(2)) >
                  0
                    ? (
                        userCoins?.balance?.toFixed(2) -
                        profileData?.availableTurnoverFinal?.toFixed(2)
                      ).toFixed(2)
                    : 0}
                </span>
              </span>
            </div>
          </div>{" "}
          <div
            style={{
              background: "white",
              padding: "10px",
              borderRadius: "5px",
              marginBottom: "10px",
              display: "flex",
              justifyContent: "space-between",
              alignItems: "flex-end",
            }}
          >
            <div className="d-flex flex-column align-items-start ">
              <span style={{ fontWeight: "600", fontSize: "16px" }}>
                Holding Amount
              </span>
              <span
                style={{
                  fontSize: "1.2rem",
                  color: "black",
                }}
              >
                INR{" "}
                <span style={{ fontWeight: "600", paddingLeft: "4px" }}>
                  {profileData?.availableTurnoverFinal > 0
                    ? Number(profileData?.availableTurnoverFinal).toFixed(2)
                    : 0}
                </span>
              </span>
            </div>
            <Button
              onClick={() => navigate("/turnover")}
              className="btn-submit w-25"
            >
              TurnOver
            </Button>
          </div>
          <div
            style={{
              background: "white",
              padding: "10px",
              borderRadius: "5px",
            }}
          >
            <h5>{t("WithDraw_Funds")}</h5>
            <div
              style={{
                color: "red",
                fontSize: "12px",
                padding: "0",
                margin: "0",
              }}
            >
              <p className="p-0 m-0">1. {t("WithdrawLine1")}.</p>
              <p className="p-0 m-0">2. {t("WithdrawLine2")}.</p>
              <p className="p-0 m-0">3. {t("WithdrawLine3")}</p>
              <p className="p-0 m-0">4. {t("WithdrawLine4")}.</p>
              <p className="p-0 m-0">5. {t("WithdrawLine5")}.</p>
              <p className="p-0 m-0">6. {t("WithdrawLine6")}.</p>
            </div>
          </div>
          <h5 className="mt-2 mb-2 text-black">{t("Please_fields")}</h5>
          <div className="d-flex justify-content-between p-1">
            <Button
              style={{
                background: "#0f2327",
                border: "none",
                marginRight: "10px",
                fontSize: "14px",
              }}
            >
              {t("Use_Account")}
            </Button>{" "}
            <Button
              onClick={() => getBankDetail()}
              style={{
                background: "#0f2327",
                border: "none",
                fontSize: "14px",
              }}
            >
              {t("Previous_Account")}
            </Button>
          </div>
          <div
            className="usrTrans-wrapper common-box form-f mt-2"
            style={{
              borderRadius: "5px",
              padding: "5px",
              background: "white",
            }}
          >
            <div
              className="withdraw-form usrTrans-form"
              style={{ marginBottom: "90px", background: "white" }}
            >
              <Form
                onSubmit={handleSubmit(AfterPinSubmit)}
                id="deposit_form"
                class="deposit_form"
              >
                <div
                  style={{ background: "white" }}
                  class="usrTrans-seperate mt-0 p-1 deposit-amount"
                >
                  <div class="transaction-title">
                    <span>{t("Amount")}</span>
                    <span class="important-icon">*</span>
                  </div>
                  <div class="transaction-option m-auto">
                    <input
                      {...register("amount", {
                        required: {
                          value: true,
                          message: t("Please_amount"),
                        },
                        pattern: {
                          value: /^[0-9]*$/,
                          message: t("Please_number"),
                        },
                        validate: (value) => {
                          if (value > Number.parseInt(userCoins?.balance)) {
                            return t("Amount_should_balance");
                          } else if (value > 200000) {
                            return t("Max_Withdraw_greater");
                          } else if (value < 500) {
                            return t("Min_Withdraw_should");
                          }
                        },
                      })}
                      type="text"
                      class="text-input"
                      id="depositAmt"
                      placeholder="0.00"
                    />
                  </div>
                  {errors?.amount?.message && (
                    <div class="transaction-errMsg text-danger depositMsg">
                      {errors?.amount?.message}
                    </div>
                  )}
                </div>
                <div
                  style={{ background: "white" }}
                  class="usrTrans-seperate mt-0 p-1 deposit-amount"
                >
                  <div class="transaction-title">
                    <span>{t("IFSC_Code")}</span>
                    <span class="important-icon">*</span>
                  </div>
                  <div class="transaction-option m-auto">
                    <input
                      {...register("ifsc_code", {
                        required: {
                          value: true,
                          message: t("Enter_ifsc"),
                        },
                      })}
                      type="text"
                      class="text-input"
                      placeholder="0.00"
                    />
                  </div>
                  {errors?.ifsc_code?.message && (
                    <div class="transaction-errMsg text-danger depositMsg">
                      {errors?.ifsc_code?.message}
                    </div>
                  )}
                </div>
                {/* <div class="usrTrans-seperate mt-0 p-1 deposit-amount">
                    <div class="transaction-title">
                      <span>{"Bank"}</span>
                      <span class="important-icon">*</span>
                    </div>
                    <div class="transaction-option m-auto">
                      <select
                        {...register("bank", {
                          required: {
                            value: true,
                            message: "Please select bank",
                          },
                          onChange: (e) => {
                            getBankDetail(e?.target?.value);
                          },
                        })}
                        id="depositBankId"
                        class="gatewayBankSelect"
                      >
                        <option value="">{"Select Bank"}</option>
                        {bank?.length > 0 &&
                          bank?.map((res) => {
                            return (
                              <option value={res?._id}>{res?.bank_name}</option>
                            );
                          })}
                      </select>
                    </div>
                    {errors?.bank?.message && (
                      <div class="transaction-errMsg text-danger depositMsg">
                        {errors?.bank?.message}
                      </div>
                    )}
                  </div>{" "} */}
                <div
                  style={{ background: "white" }}
                  class="usrTrans-seperate mt-0 p-1 deposit-amount"
                >
                  <div class="transaction-title">
                    <span>{t("Account_Number")}</span>{" "}
                    <span class="important-icon">*</span>
                    <span class="copyBtn bg-gradient-secondary">
                      <i class="fas fa-copy"></i>
                    </span>
                  </div>
                  <div class="transaction-option m-auto">
                    <input
                      {...register("accountNumber", {
                        required: {
                          value: true,
                          message: t("Please_account_number"),
                        },
                      })}
                      type="number"
                      class="text-input"
                      id="depositAccName"
                    />
                  </div>{" "}
                  {errors?.accountNumber?.message && (
                    <div class="transaction-errMsg text-danger depositMsg">
                      {errors?.accountNumber?.message}
                    </div>
                  )}
                </div>
                <div
                  style={{ background: "white" }}
                  class="usrTrans-seperate mt-0 p-1 deposit-amount"
                >
                  <div class="transaction-title">
                    <span>{t("Confirm_Account_Number")}</span>{" "}
                    <span class="important-icon">*</span>
                    <span class="copyBtn bg-gradient-secondary">
                      <i class="fas fa-copy"></i>
                    </span>
                  </div>
                  <div class="transaction-option m-auto">
                    <input
                      {...register("confirmAccountNumber", {
                        required: "Please enter confirm account number",
                        validate: (value) => {
                          if (value == watch("accountNumber")) {
                            return true;
                          } else {
                            return t("confirm_not_same");
                          }
                        },
                      })}
                      // disabled
                      type="number"
                      class="text-input"
                      id="depositAccName"
                    />
                  </div>{" "}
                  {errors?.confirmAccountNumber?.message && (
                    <div class="transaction-errMsg text-danger depositMsg">
                      {errors?.confirmAccountNumber?.message}
                    </div>
                  )}
                </div>
                <div
                  style={{ background: "white" }}
                  class="usrTrans-seperate mt-0 p-1 deposit-amount"
                >
                  <div class="transaction-title">
                    <span>{t("Account_Name")}</span>{" "}
                    <span class="important-icon">*</span>
                    <span class="copyBtn bg-gradient-secondary">
                      <i class="fas fa-copy"></i>
                    </span>
                  </div>
                  <div class="transaction-option m-auto">
                    <input
                      {...register("accountName", {
                        required: {
                          value: true,
                          message: t("Please_account_name"),
                        },
                      })}
                      // disabled
                      class="text-input"
                      id="depositAccName"
                    />
                  </div>{" "}
                  {errors?.accountName?.message && (
                    <div class="transaction-errMsg text-danger depositMsg">
                      {errors?.accountName?.message}
                    </div>
                  )}
                </div>
                <div
                  style={{ background: "white" }}
                  class="usrTrans-seperate mt-0 p-1 deposit-amount"
                >
                  <div className="transaction-title">
                    <span>{otpSend ? t("OTP") : t("Mobile_Number")}</span>{" "}
                    <span class="important-icon">*</span>
                    <span className="important-icon"></span>
                  </div>
                  <div className="transaction-option m-auto">
                    {otpSend ? (
                      <Form.Control
                        type="text"
                        placeholder={t("Enter_OTP")}
                        maxlength="6"
                        className={
                          errors1.verificationCode ? " is-invalid " : ""
                        }
                        {...register("verificationCode", {
                          required: t("Please_enter_OTP"),
                          pattern: {
                            value: /^[0-9]+$/,
                            message: t("Please_valid_number"),
                          },
                        })}
                      />
                    ) : (
                      <Controller
                        control={control}
                        name="mobile"
                        rules={{
                          required: t("Enter_Mobile"),
                          validate: (value) => {
                            let inputValue = value
                              ?.toString()
                              ?.slice(
                                inputRef?.current?.state?.selectedCountry
                                  ?.countryCode?.length,
                                value?.length
                              );
                            if (inputValue?.length < 10) {
                              return t("MobileMin");
                            } else if (inputValue?.length > 12) {
                              return t("MobileMax");
                            } else {
                              return true;
                            }
                          },
                        }}
                        render={({ field: { ref, ...field } }) => (
                          <>
                            <PhoneInput
                              {...field}
                              inputExtraProps={{
                                ref,
                                required: true,
                                autoFocus: true,
                              }}
                              ref={inputRef}
                              inputStyle={{
                                width: "100%",
                                height: "38px",
                              }}
                              // countryCodeEditable={false}
                              // disableDropdown={true}
                              country={"in"}
                              onlyCountries={["in", "gb", "ae", "au"]}
                            />
                          </>
                        )}
                      />
                    )}
                  </div>
                  <Button
                    disabled={otpSend}
                    onClick={() => {
                      if (!isEmpty(watch("mobile"))) {
                        let value = watch("mobile")
                          ?.toString()
                          ?.slice(
                            inputRef?.current?.state?.selectedCountry
                              ?.countryCode?.length,
                            watch("mobile")?.length
                          );
                        if (inRange(value?.length, 9, 12)) {
                          userSendOtp(watch("mobile"));
                        } else {
                          toast.error(t("MobileMin"));
                        }
                      } else {
                        toast.error(t("Enter_Mobile"));
                      }
                    }}
                    style={{ color: "white", marginRight: "5px" }}
                    className="theme-btn py-1 px-3 fs-6 mt-2"
                  >
                    {otpSend ? t("Resend_Otp") : t("Get_OTP")}
                  </Button>
                </div>{" "}
                <div
                  style={{ background: "white" }}
                  class="usrTrans-seperate mt-0 p-1 deposit-amount"
                >
                  <div class="transaction-title">
                    <span>{t("Your_Password")}</span>{" "}
                    <span class="important-icon">*</span>
                    <span class="copyBtn bg-gradient-secondary">
                      <i class="fas fa-copy"></i>
                    </span>
                  </div>
                  <div class="transaction-option m-auto">
                    <Form.Control
                      type={"password"}
                      placeholder={t("Password")}
                      maxLength={15}
                      {...register("password", {
                        required: t("Enter_Password"),
                        minLength: {
                          value: 8,
                          message: t("PasswordMin"),
                        },
                        maxLength: {
                          value: 16,
                          message: t("PasswordMax"),
                        },
                      })}
                    />
                  </div>{" "}
                  {errors?.password?.message && (
                    <div class="transaction-errMsg text-danger depositMsg">
                      {errors?.password?.message}
                    </div>
                  )}
                </div>
                <div
                  style={{ background: "white" }}
                  className="d-flex w-100 justify-content-start mt-2 p-2"
                >
                  <input
                    checked={checkbox}
                    onClick={(e) => setCheckBox(e.target.checked)}
                    type="checkbox"
                    style={{ marginRight: "10px", width: "22px" }}
                  />
                  <span style={{ fontWeight: "600", fontSize: "12px" }}>
                    {t("Agree_with")}&nbsp;
                    <span>{t("payment_policy")}.</span>
                  </span>
                </div>
                <div
                  style={{ background: "white" }}
                  className="usrTrans-seperate"
                >
                  <div className="transaction-option">
                    <div className="transaction-btn">
                      <button
                        type="submit"
                        className="btn-submit bg-gradient-primary"
                      >
                        {t("Withdraw")}
                      </button>
                    </div>
                  </div>
                </div>
              </Form>
            </div>
          </div>
        </div>
      </div>

      {verifyEdit?.status && (
        <Modal centered show={verifyEdit?.status} onHide={handleCloseVerify}>
          <Modal.Header closeButton>
            <Modal.Title>{t("Verify_OTP")}</Modal.Title>
          </Modal.Header>
          <Form onSubmit={handleSubmit1(onSubmit1)}>
            <Modal.Body>
              {verifyEdit?.phoneStatus == "add" && (
                <>
                  <Form.Group className="form-group d-flex mb-3">
                    <Controller
                      className="form-group d-flex"
                      control={control1}
                      name="mobile"
                      rules={{
                        required: t("Enter_Mobile"),
                        validate: (value) => {
                          let inputValue = value
                            ?.toString()
                            ?.slice(
                              inputRef?.current?.state?.selectedCountry
                                ?.countryCode?.length,
                              value?.length
                            );
                          if (inputValue?.length < 10) {
                            return t("MobileMin");
                          } else if (inputValue?.length > 12) {
                            return t("MobileMax");
                          } else {
                            return true;
                          }
                        },
                      }}
                      render={({ field: { ref, ...field } }) => (
                        <>
                          <PhoneInput
                            {...field}
                            inputExtraProps={{
                              ref,
                              required: true,
                              autoFocus: true,
                            }}
                            ref={inputRef}
                            inputStyle={{
                              width: "100%",
                              height: "38px",
                            }}
                            // countryCodeEditable={false}
                            // disableDropdown={true}
                            country={"in"}
                            onlyCountries={["in", "gb", "ae", "au"]}
                          />
                        </>
                      )}
                    />
                  </Form.Group>
                  {errors1?.mobile?.message && (
                    <div className="text-danger">
                      {errors1?.mobile?.message}
                    </div>
                  )}
                </>
              )}

              <div
                style={{ position: "relative", width: `100%` }}
                className="mb-2"
              >
                <Form.Group className="d-flex ">
                  <div
                    style={{ position: "relative", width: `100%` }}
                    className="mb-2"
                  >
                    <Form.Control
                      type="text"
                      placeholder={t("Enter_verification_code")}
                      maxlength="6"
                      onChange={(e) => {
                        setVerifyEdit({
                          ...verifyEdit,
                          verificationCode: e.target.value,
                        });
                      }}
                      className={errors1.verificationCode ? " is-invalid " : ""}
                      {...register1("verificationCode", {
                        required: t("Please_verification_code"),
                        pattern: {
                          value: /^[0-9]+$/,
                          message: t("Please_valid_number"),
                        },
                      })}
                    />
                  </div>
                </Form.Group>
                {errors1?.verificationCode?.message && (
                  <div className="text-danger">
                    {errors1?.verificationCode?.message}
                  </div>
                )}
              </div>

              {errors?.verificationCode?.message && (
                <div className="text-danger">
                  {errors?.verificationCode?.message}
                </div>
              )}

              <Button
                disabled={seconds > 0 || minutes > 0}
                onClick={() => {
                  userSendOtp(watch("mobile"));
                }}
                style={{
                  color: "white",
                }}
                className="theme-btn py-1 px-3 fs-6"
              >
                {t("Resend_Otp")}
              </Button>

              <div className="countdown-text">
                {seconds > 0 || minutes > 0 ? (
                  <p>
                    {t("Time_Remaining")}:{" "}
                    {minutes < 10 ? `0${minutes}` : minutes}:
                    {seconds < 10 ? `0${seconds}` : seconds}
                  </p>
                ) : (
                  <p>{t("t_recieve_code")}?</p>
                )}
              </div>
            </Modal.Body>
            <Modal.Footer>
              <button
                type="submit"
                className="btn-submit bg-gradient-primary"
                disabled={isLoader1 ? "disabled" : ""}
              >
                <span> {isLoader1 ? t("Loading") : t("Confirm")}</span>
              </button>
            </Modal.Footer>
          </Form>
        </Modal>
      )}
      {confirmPin && !isEmpty(afterData) && (
        <ConfirmPin
          show={confirmPin}
          handelClose={() => setConfirmPin(false)}
          pin={pin}
          setPin={setPin}
          onSubmit={AfterPinSubmit}
        />
      )}
    </SidebarLayout>
  );
};

export default WithDraw;
