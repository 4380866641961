import React from "react";
import Row from "react-bootstrap/esm/Row";
import BatBlue from "../../img/bat-blue.png";
import trophy from "../../img/trophy.png";
import telecase from "../../img/telecast.png";
import { useNavigate } from "react-router-dom";
import Col from "react-bootstrap/esm/Col";
import obj from "../../Utils/helpers";
import { useTranslation } from "react-i18next";
import { isEmpty, upperCase } from "lodash";
const ListSection = ({ data, heading, allOdds, type, score, spark }) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const emptyOdds = () => {
    return (
      <div className="d-flex">
        <div className="bluBoxs">
          -<span>-</span>
        </div>
        <div className="redBoxs">
          -<span>-</span>
        </div>
      </div>
    );
  };
  return (
    <div className="leagueBox mb-4">
      <div className="title">
        <Row>
          <Col md={6}>
            <span className="live">{upperCase(type)}</span>
            <img src={BatBlue} style={{ maxWidth: "20px" }} /> {heading}
          </Col>
          <Col md={6} className="text-dark text-center">
            <Row>
              <Col className="text-center text-white" md={4}>
                1
              </Col>
              <Col className="text-center text-white" md={4}>
                2
              </Col>
              <Col className="text-center text-white" md={4}>
                3
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      {data?.map((item) => {
        let Odds =
          allOdds?.length > 0
            ? allOdds?.find((res) => {
                return res?.mi == item?.marketId;
              })
            : {};
        // let odds_lay =
        //   Odds?.rt?.length > 0 ? Odds?.rt?.filter((todd) => !todd?.ib) : [];
        // let odds_back =
        //   Odds?.rt?.length > 0 ? Odds?.rt?.filter((todd) => todd?.ib) : [];

        let scoreUpdated =
          score?.length > 0
            ? score?.find((res) => {
                return res?.eventId == item?.eventId;
              })?.score || ""
            : item?.outcome && item?.outcome != undefined
            ? item?.outcome
            : "";
        if (item?.eventName !== "Indian Premier League") {
          return (
            <div
              onClick={() =>
                navigate(`/match/${item?.eventId}/${item?.marketId}`)
              }
              // active
              className="laegueRow pointer-event"
              style={{
                borderBottom: "1px solid rgb(221, 221, 221)",
                cursor: "pointer",
              }}
            >
              <Row>
                <Col md={6}>
                  {/* <Row className="mb-1">
                  <Col md={5}>{item?.eventName?.split(" v ")[0]}</Col>
                </Row>
                <Row>
                  <Col md={5}>{item?.eventName?.split(" v ")[1]}</Col>
                </Row> */}
                  <div
                    className="d-flex flex-column align-items-start"
                    style={{ padding: "4px 7px" }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "14px",
                        justifyContent: "space-between",
                        width: "100%",
                        marginBottom: "0px",
                        color: "black",
                      }}
                    >
                      <span>{item?.eventName}</span>
                      <div
                        style={{
                          color: "rgb(255, 184, 12)",
                          marginLeft: "10px",
                          fontWeight: "600",
                        }}
                      >
                        {scoreUpdated}
                      </div>
                    </div>
                    <div className="d-flex justify-content-between align-items-center w-100">
                      <span style={{ color: "#959595", fontSize: "10px" }}>
                        {obj.msgDateFormatNew(item?.eventDateTime)}{" "}
                        {obj.msgDateFormatTime(item?.eventDateTime)} |{" "}
                        {item?.seriesName}
                      </span>
                      <div className="d-flex align-items-center">
                        {" "}
                        <>
                          {/* {!item?.eventName.includes(" SRL") && (
                          <> */}
                          {(item?.hasFancy || item?.hasBookmaker) && (
                            <>
                              <img
                                src="../assets/images/casino-icon/f.png"
                                alt=""
                                className="list-icon"
                              />
                              <img
                                src="../assets/images/casino-icon/b.png"
                                alt=""
                                className="list-icon"
                              />
                            </>
                          )}
                          {/* </>
                        )} */}

                          {item?.hasSportBook && (
                            <img
                              src="../assets/images/casino-icon/s.png"
                              alt=""
                              className="list-icon"
                            />
                          )}
                          {/* 
                        {item?.eventName.includes(" SRL") && (
                          <svg
                            width="100%"
                            height="12px"
                            viewBox="0 0 34 10"
                            fill="none"
                            xmlns="http://www.w3.org/2000/svg"
                          >
                            <g clip-path="url(#clip0_3432_39516)">
                              <path
                                d="M9.165 0C9.625 0 10 0.374167 10 0.835V9.165C10 9.625 9.62583 10 9.165 10H0.835C0.725346 10 0.616766 9.9784 0.515459 9.93644C0.414152 9.89448 0.322103 9.83297 0.244566 9.75543C0.167029 9.6779 0.105523 9.58585 0.0635606 9.48454C0.0215979 9.38323 0 9.27465 0 9.165L0 0.835C0 0.375 0.374167 0 0.835 0H9.165Z"
                                fill="#D15BE4"
                              ></path>
                              <g clip-path="url(#clip1_3432_39516)">
                                <path
                                  d="M8.571 3.76974L9.00005 2.54541H3.04486C2.74453 2.54541 2.47852 2.72396 2.38413 2.99603L1.28577 6.09086H3.39668L3.57688 5.58923H7.4469L7.8073 4.56045H3.93728L4.22046 3.76974H8.571Z"
                                  fill="white"
                                ></path>
                                <path
                                  d="M3.26438 6.36353H1.16192L1.03425 6.69686C0.889542 7.06807 1.22151 7.45443 1.68967 7.45443H7.14583L7.57143 6.36353H3.25587H3.26438Z"
                                  fill="white"
                                ></path>
                              </g>
                              <path
                                d="M29.16 7.5001V3.1986H30.0805V7.5001H29.16ZM27.886 3.7936V3.0271H31.3475V3.7936H27.886Z"
                                fill="#D15BE4"
                              ></path>
                              <path
                                d="M26.598 7.5001L26.4965 6.6286C26.4801 6.47227 26.4475 6.3416 26.3985 6.2366C26.3495 6.1316 26.276 6.05227 26.178 5.9986C26.08 5.9426 25.9446 5.9146 25.772 5.9146L24.6905 5.9111V5.2041L25.828 5.2006C26.0823 5.2006 26.2643 5.14227 26.374 5.0256C26.486 4.9066 26.542 4.73743 26.542 4.5181V4.4341C26.542 4.2171 26.486 4.0526 26.374 3.9406C26.2643 3.82627 26.08 3.7691 25.821 3.7691H24.68V3.0271H25.9505C26.4755 3.0271 26.8581 3.13327 27.0985 3.3456C27.3411 3.55793 27.4625 3.8601 27.4625 4.2521V4.3501C27.4625 4.6441 27.3913 4.87743 27.249 5.0501C27.1066 5.22277 26.8803 5.32543 26.57 5.3581V5.4841L26.3145 5.3581C26.5805 5.37677 26.7858 5.43277 26.9305 5.5261C27.0775 5.61943 27.1848 5.74893 27.2525 5.9146C27.3201 6.07793 27.3715 6.28093 27.4065 6.5236L27.543 7.5001H26.598ZM24.0535 7.5001V3.0271H24.9495V5.4561L24.9565 5.7956V7.5001H24.0535Z"
                                fill="#D15BE4"
                              ></path>
                              <path
                                d="M21.4314 7.60162C20.8131 7.60162 20.3453 7.44062 20.0279 7.11862C19.7129 6.79428 19.5554 6.33112 19.5554 5.72912V4.81912C19.5554 4.21478 19.7129 3.74928 20.0279 3.42262C20.3453 3.09595 20.8131 2.93262 21.4314 2.93262C22.0498 2.93262 22.5164 3.09595 22.8314 3.42262C23.1488 3.74928 23.3074 4.21478 23.3074 4.81912V5.72912C23.3074 6.33112 23.1499 6.79428 22.8349 7.11862C22.5199 7.44062 22.0521 7.60162 21.4314 7.60162ZM21.4314 6.83862C21.7464 6.83862 21.9821 6.74528 22.1384 6.55862C22.2948 6.37195 22.3729 6.10945 22.3729 5.77112V4.77712C22.3729 4.42712 22.2948 4.15878 22.1384 3.97212C21.9821 3.78312 21.7464 3.68862 21.4314 3.68862C21.1188 3.68862 20.8843 3.78312 20.7279 3.97212C20.5716 4.15878 20.4934 4.42712 20.4934 4.77712V5.77112C20.4934 6.10945 20.5716 6.37195 20.7279 6.55862C20.8843 6.74528 21.1188 6.83862 21.4314 6.83862Z"
                                fill="#D15BE4"
                              ></path>
                              <path
                                d="M16.2441 6.1561V5.4071H17.3851C17.6371 5.4071 17.8191 5.34877 17.9311 5.2321C18.0454 5.1131 18.1026 4.9416 18.1026 4.7176V4.4621C18.1026 4.23343 18.0466 4.06193 17.9346 3.9476C17.8226 3.83093 17.6406 3.7726 17.3886 3.7726H16.2441V3.0271H17.4411C17.9848 3.0271 18.3826 3.1496 18.6346 3.3946C18.8889 3.6396 19.0161 3.98727 19.0161 4.4376V4.7456C19.0161 5.19593 18.8889 5.5436 18.6346 5.7886C18.3826 6.0336 17.9848 6.1561 17.4411 6.1561H16.2441ZM15.6316 7.5001V3.0271H16.5346V5.6206L16.5381 6.0371V7.5001H15.6316Z"
                                fill="#D15BE4"
                              ></path>
                              <path
                                d="M13.2396 7.59104C13.032 7.59104 12.8371 7.57587 12.6551 7.54554C12.4755 7.5152 12.3121 7.47787 12.1651 7.43354C12.0205 7.38687 11.8956 7.3402 11.7906 7.29354L11.7101 6.42554C11.8921 6.5212 12.108 6.60754 12.3576 6.68454C12.6073 6.7592 12.8733 6.79654 13.1556 6.79654C13.4543 6.79654 13.6701 6.75337 13.8031 6.66704C13.9361 6.5807 14.0026 6.4477 14.0026 6.26804V6.22954C14.0026 6.11287 13.9735 6.0172 13.9151 5.94254C13.8591 5.86554 13.7646 5.79787 13.6316 5.73954C13.4986 5.6812 13.3178 5.6217 13.0891 5.56104C12.7671 5.47237 12.5023 5.37204 12.2946 5.26004C12.0893 5.1457 11.9376 5.00454 11.8396 4.83654C11.7416 4.6662 11.6926 4.4527 11.6926 4.19604V4.15404C11.6926 3.76204 11.8245 3.46104 12.0881 3.25103C12.3541 3.04104 12.7461 2.93604 13.2641 2.93604C13.5861 2.93604 13.8626 2.9652 14.0936 3.02354C14.3246 3.08187 14.5183 3.14954 14.6746 3.22654L14.7551 4.03504C14.5801 3.9417 14.3795 3.8647 14.1531 3.80404C13.9291 3.74104 13.6841 3.70954 13.4181 3.70954C13.2198 3.70954 13.0623 3.7282 12.9456 3.76554C12.8313 3.80287 12.7485 3.8577 12.6971 3.93003C12.6481 4.00237 12.6236 4.08987 12.6236 4.19254V4.20304C12.6236 4.30804 12.6493 4.39904 12.7006 4.47604C12.7543 4.55304 12.8488 4.6242 12.9841 4.68954C13.1195 4.75254 13.312 4.81787 13.5616 4.88554C13.879 4.9742 14.138 5.06987 14.3386 5.17254C14.5416 5.2752 14.6921 5.4082 14.7901 5.57154C14.8881 5.73487 14.9371 5.9507 14.9371 6.21904V6.28204C14.9371 6.7137 14.796 7.04037 14.5136 7.26204C14.2336 7.48137 13.809 7.59104 13.2396 7.59104Z"
                                fill="#D15BE4"
                              ></path>
                              <path
                                fill-rule="evenodd"
                                clip-rule="evenodd"
                                d="M1 1H10H33V9H10H1V1ZM0 0H1H33H34V1V9V10H33H1H0V9V1V0Z"
                                fill="#D15BE4"
                              ></path>
                            </g>
                            <defs>
                              <clipPath id="clip0_3432_39516">
                                <rect
                                  width="34"
                                  height="10"
                                  rx="1"
                                  fill="white"
                                ></rect>
                              </clipPath>
                              <clipPath id="clip1_3432_39516">
                                <rect
                                  width="8"
                                  height="6"
                                  fill="white"
                                  transform="translate(1 2)"
                                ></rect>
                              </clipPath>
                            </defs>
                          </svg>
                        )} */}
                        </>
                      </div>
                    </div>
                  </div>
                </Col>

                {item?.runners?.length > 0 && !isEmpty(Odds) && (
                  <Col xs={6} className="text-dark text-center">
                    <Row className="mx-0">
                      {(item?.runners?.length == 2
                        ? [
                            item?.runners[0],
                            {
                              SelectionId: "",
                              RunnerName: "",
                            },
                            item?.runners[1],
                          ]
                        : item?.runners
                      )?.map((res) => {
                        let odds_lay = Odds?.rt?.filter(
                          (todd) => !todd?.ib && todd?.ri == res?.SelectionId
                        );
                        let odds_back = Odds?.rt?.filter(
                          (todd) => todd?.ib && todd?.ri == res?.SelectionId
                        );
                        return (
                          <Col className="text-center px-0" xs={4}>
                            <div className="d-flex ">
                              <div
                                className={`bluBoxs ${spark && "spark-back"}`}
                              >
                                {odds_back[0]?.rt || "-"}
                                <span>{odds_back[0]?.bv || "-"}</span>
                              </div>
                              <div
                                className={`redBoxs  ${spark && "spark-lay"}`}
                              >
                                {odds_lay[0]?.rt || "-"}
                                <span>{odds_lay[0]?.bv || "-"}</span>
                              </div>
                            </div>
                            {/* ) : (
                            <div className="d-flex">
                              <div className="bluBoxs">-</div>
                              <div className="redBoxs">-</div>
                            </div>
                          )} */}
                          </Col>
                        );
                      })}

                      {/* <Col className="text-center px-0" xs={4}>
                      {odds_back?.length >= 2 ? (
                        <div className="d-flex ">
                          <div className={`bluBoxs ${spark && "spark-back"}`}>
                            {odds_back[1]?.rt}
                          </div>
                          <div className={`redBoxs  ${spark && "spark-lay"}`}>
                            {odds_lay[1]?.rt}
                          </div>
                        </div>
                      ) : (
                        <div className="d-flex">
                          <div className="bluBoxs">-</div>
                          <div className="redBoxs">-</div>
                        </div>
                      )}
                    </Col> */}
                    </Row>{" "}
                  </Col>
                )}
                {/* <Col md={5} className="text-dark text-center">
                <Row className="mx-0">
                  <Col className="text-center px-0" md={4}>
                    {odds_back?.length >= 2 ? (
                      <div className="d-flex">
                      <div className={`bluBoxs ${spark && "spark-back"}`}>
                          {odds_back[1]?.rt}
                          <span>{odds_back[1]?.bv}</span>
                        </div>
                        <div className={`redBoxs  ${spark && "spark-lay"}`}>
                          {odds_lay[1]?.rt}
                          <span>{odds_lay[1]?.bv}</span>
                        </div>
                      </div>
                    ) : (
                      emptyOdds()
                    )}
                  </Col>
                  <Col className="text-center px-0" md={4}>
                    {odds_back?.length >= 1 ? (
                      <div className="d-flex">
                          <div className={`bluBoxs ${spark && "spark-back"}`}>
                          {odds_back[0]?.rt}
                          <span>{odds_back[0]?.bv}</span>
                        </div>
                        <div className={`redBoxs  ${spark && "spark-lay"}`}>
                          {odds_lay[0]?.rt}
                          <span>{odds_lay[0]?.bv}</span>
                        </div>
                      </div>
                    ) : (
                      emptyOdds()
                    )}
                  </Col>
                  <Col className="text-center px-0" md={4}>
                    {" "}
                    {odds_back?.length >= 3 ? (
                      <div className="d-flex">
                          <div className={`bluBoxs ${spark && "spark-back"}`}>
                          {odds_lay[2]?.rt}
                          <span>{odds_lay[2]?.bv}</span>
                        </div>
                        <div className={`redBoxs  ${spark && "spark-lay"}`}>
                          {odds_lay[2]?.rt}
                          <span>{odds_lay[2]?.bv}</span>
                        </div>
                      </div>
                    ) : (
                      emptyOdds()
                    )}
                  </Col>
                </Row>
              </Col> */}
              </Row>
            </div>
          );
        }
      })}
    </div>
  );
};

export default ListSection;
