import React, { useContext, useEffect, useState } from "react";
import {
  Button,
  Col,
  Container,
  FloatingLabel,
  Form,
  Row,
} from "react-bootstrap";
import { FaLock, FaEye, FaEyeSlash } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import apiPath from "../Utils/apiPath";
import AuthContext from "../context/AuthContext";
import jwt_decode from "jwt-decode";
import { Controller, useForm } from "react-hook-form";
import { inRange, isEmpty, pick } from "lodash";
import { apiPost } from "../Utils/apiFetch";
import { preventMaxInput, validationRules } from "../Utils/constants";
import PhoneInput from "react-phone-input-2";
import { FaUser } from "react-icons/fa";
import "react-phone-input-2/lib/style.css";
import { useRef } from "react";
import { toast } from "react-toastify";
import { IoIosArrowBack } from "react-icons/io";
import loginImg from "../img/login-img.png";
import logo from "../img/header-logo.png";
import { useTranslation } from "react-i18next";
const ForgotPassword = () => {
  const [showPassword, setShowPassword] = useState({
    first: true,
    second: true,
    third: true,
  });

  const { logoutUser, user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(true);
  const handleShow = () => {
    setShowPassword(!showPassword);
  };
  const handleShowConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };
  const [success, setSuccess] = useState({
    check: true,
    message: "",
  });
  const {
    register,
    control,
    handleSubmit,
    formState: { errors },
    unregister,
    reset,
    watch,
    trigger,
    setValue,
  } = useForm({});

  useEffect(() => {
    if (!isEmpty(watch("confirmPassword"))) {
      if (watch("newPassword")) {
        trigger("confirmPassword");
      }
    }
  }, [watch("newPassword")]);

  const onSubmit = async (body) => {
    const obj = {
      phone_number: body?.mobile,
      username: body?.username,
      otp: body?.verificationCode,
      pin: body?.newPassword,
    };
    const { status, data } = await apiPost(apiPath.forgotPassword, obj);
    if (status === 200) {
      if (data.success) {
        navigate("/");
        toast.success(data?.message);
      }
    } else {
      toast.error(data?.message);
    }
  };
  const { t } = useTranslation();
  const [otpSend, setOtpSend] = useState(false);
  const userSendOtp = async (value) => {
    const { status, data } = await apiPost(apiPath.sendOtp, {
      phone_number: value,
      username: watch("username"),
      type: "forgot",
    });
    if (status == 200) {
      if (data?.success) {
        setOtpSend(true);
        if (!isEmpty(data?.results?.otp)) {
          setValue("verificationCode", data?.results?.otp);
        }
      } else {
        toast.error(data?.message);
      }
    }
  };
  return (
    <div className="login">
      <div
        style={{ top: "1%", left: "1%" }}
        className="d-flex position-absolute align-items-center justify-content-start"
      >
        <div
          className="left-arrow-icon d-flex justify-content-center align-items-center"
          style={{
            background: "white",
            borderRadius: "50%",
            marginLeft: "8px",
            marginTop: "8px",
          }}
          onClick={() => navigate("/")}
        >
          <IoIosArrowBack size={30} className="fs-1 p-1 cursor-pointer" />
        </div>
      </div>
      <Container className="h-100 align-middle">
        <Row className="h-100 align-items-center align-middle justify-content-center">
          <Col md={11}>
            <Row>
              <Col md={7} className="text-start">
                <img
                  data-aos="zoom-in-down"
                  src={loginImg}
                  style={{ width: "80%" }}
                />
              </Col>
              <Col md={5}>
                <div data-aos="flip-up" className="text-center mb-4">
                  <img src={logo} style={{ width: "220px" }} />
                </div>
                <Form
                  onSubmit={handleSubmit(onSubmit)}
                  className="bg-white p-2 change-password-form"
                >
                  <div data-aos="zoom-in" className="loginForm">
                    <div className="d-flex flex-column align-items-center mb-4">
                      <h5 className="m-0">Forgot Password</h5>
                      {/* <span>Login in to you account to continue</span> */}
                    </div>
                    <Form.Label>Username</Form.Label>
                    <Form.Group className="mb-2">
                      <Form.Control
                        type="text"
                        placeholder="Username"
                        className={errors.username ? "is-invalid" : ""}
                        {...register("username", {
                          required: "Please enter Username",
                        })}
                        id="username"
                      />{" "}
                      {errors.username && errors.username.message && (
                        <label className="invalid-feedback text-left">
                          {errors.username.message}
                        </label>
                      )}
                    </Form.Group>
                    <Form.Label>Phone</Form.Label>
                    <Controller
                      control={control}
                      name="mobile"
                      rules={{
                        required: t("Enter_Mobile"),
                        validate: (value) => {
                          let inputValue = value
                            ?.toString()
                            ?.slice(
                              inputRef?.current?.state?.selectedCountry
                                ?.countryCode?.length,
                              value?.length
                            );
                          if (inputValue?.length < 10) {
                            return t("MobileMin");
                          } else if (inputValue?.length > 12) {
                            return t("MobileMax");
                          } else {
                            return true;
                          }
                        },
                      }}
                      render={({ field: { ref, ...field } }) => (
                        <>
                          <PhoneInput
                            {...field}
                            inputExtraProps={{
                              ref,
                              required: true,
                              autoFocus: true,
                            }}
                            ref={inputRef}
                            inputStyle={{
                              width: "100%",
                              height: "38px",
                            }}
                            // countryCodeEditable={false}
                            // disableDropdown={true}
                            country={"in"}
                            onlyCountries={["in", "gb", "ae", "au"]}
                          />
                        </>
                      )}
                    />
                    <Button
                      // disabled={otpSend}
                      onClick={() => {
                        if (
                          !isEmpty(watch("mobile")) &&
                          !isEmpty(watch("username"))
                        ) {
                          let value = watch("mobile")
                            ?.toString()
                            ?.slice(
                              inputRef?.current?.state?.selectedCountry
                                ?.countryCode?.length,
                              watch("mobile")?.length
                            );
                          if (inRange(value?.length, 8, 12)) {
                            userSendOtp(watch("mobile"));
                          } else {
                            toast.error("Mobile number must contain 9 digit");
                          }
                        } else {
                          if (isEmpty(watch("mobile"))) {
                            toast.error("Please enter mobile number");
                          }
                          if (isEmpty(watch("username"))) {
                            toast.error("Please enter username");
                          }
                        }
                      }}
                      style={{ color: "black", fontSize: "10px" }}
                      className="theme-btn py-1 fs-6 mt-2 mb-2"
                    >
                      {otpSend ? "Resend OTP" : "Get OTP"}
                    </Button>
                    {errors?.mobile?.message && (
                      <label className="invalid-feedback text-left">
                        {errors?.mobile?.message}
                      </label>
                    )}
                    {otpSend && (
                      <Form.Group className="mb-2">
                        <FloatingLabel
                          controlId="floatingPassword"
                          label="Enter OTP"
                        >
                          <Form.Control
                            type="text"
                            placeholder="Enter OTP"
                            maxlength="6"
                            className={
                              errors.verificationCode ? " is-invalid " : ""
                            }
                            {...register("verificationCode", {
                              required: "Please enter OTP",
                              pattern: {
                                value: /^[0-9]+$/,
                                message: "Please enter a valid number",
                              },
                            })}
                          />
                          <FaLock className="position-absolute translate-middle-y top-50" />
                        </FloatingLabel>
                        {errors.verificationCode &&
                          errors?.verificationCode?.message && (
                            <label className="text-danger">
                              {errors.verificationCode.message}
                            </label>
                          )}
                      </Form.Group>
                    )}
                    <Form.Label>{t("Password")}</Form.Label>
                    <Form.Group className="mb-2 position-relative">
                      <Form.Control
                        type={!showPassword ? "text" : "password"}
                        placeholder={t("Password")}
                        maxLength={15}
                        {...register("password", {
                          required: t("Enter_Password"),
                          minLength: {
                            value: 8,
                            message: t("PasswordMin"),
                          },
                          maxLength: {
                            value: 16,
                            message: t("PasswordMax"),
                          },
                        })}
                      />
                      <button
                        className=" w-25 position-absolute translate-middle-y top-50 end-0 me-2 bg-transparent border-0 text-dark p-0 "
                        onClick={() => handleShow()}
                      >
                        {!showPassword ? (
                          <FaEye className="opacity-100 fs-5" size="17" />
                        ) : (
                          <FaEyeSlash className="opacity-100 fs-5" size="17" />
                        )}
                      </button>
                    </Form.Group>{" "}
                    {errors.password && errors?.password?.message && (
                      <label className="invalid-feedback text-left">
                        {errors.password.message}
                      </label>
                    )}
                    <Form.Label>{t("Confirm_Password")}</Form.Label>
                    <Form.Group className="mb-2 position-relative">
                      <Form.Control
                        type={!showPasswordConfirm ? "text" : "password"}
                        placeholder={t("Confirm_Password")}
                        maxLength={15}
                        {...register("confirmPassword", {
                          required: t("Enter_CPassword"),
                          validate: (value) => {
                            if (!isEmpty(watch("password"))) {
                              if (value == watch("password")) {
                                return true;
                              } else {
                                return t("PasswordMatch");
                              }
                            }
                          },
                        })}
                      />
                      <button
                        className=" w-25 position-absolute translate-middle-y top-50 end-0 me-2 bg-transparent border-0 text-dark p-0 "
                        onClick={() => handleShowConfirm()}
                      >
                        {!showPasswordConfirm ? (
                          <FaEye className="opacity-100 fs-5" size="17" />
                        ) : (
                          <FaEyeSlash className="opacity-100 fs-5" size="17" />
                        )}
                      </button>
                    </Form.Group>{" "}
                    {errors.confirmPassword &&
                      errors?.confirmPassword?.message && (
                        <label className="invalid-feedback text-left">
                          {errors.confirmPassword.message}
                        </label>
                      )}
                    <div className="text-center mt-2">
                      <Button
                        type="submit"
                        className="theme-btn ms-auto px-4"
                        style={{ fontSize: "15px" }}
                      >
                        Change
                      </Button>
                    </div>
                  </div>
                </Form>
              </Col>
            </Row>
          </Col>
        </Row>
      </Container>
    </div>
  );
};

export default ForgotPassword;
