import React, { useContext, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { useForm } from "react-hook-form";
import { apiGet, apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import { toast } from "react-toastify";
import { isEmpty } from "lodash";
import { IoCloseCircle } from "react-icons/io5";
import AuthContext from "../context/AuthContext";
const Setting = () => {
  const { user, setSetting, setting } = useContext(AuthContext);
  const { handleSubmit: handleSubmit1 } = useForm({});
  const [okShown, setOkShown] = useState(true);
  const [editShown, setEditShown] = useState(false);
  const handleEditClick = (event) => {
    setEditShown(true);
    setOkShown(false);
  };
  const [mainStake, setMainStake] = useState([]);
  const [stakedata, setStakeData] = useState([]);
  const myProfile = async () => {
    const { status, data: response_users } = await apiGet(apiPath.userProfile);
    if (status === 200) {
      if (response_users.success) {
        let stakeD = response_users?.results?.editStake;
        stakeD.sort((a, b) => a - b);
        setStakeData(stakeD);
        setMainStake(response_users?.results?.editOneClickBetStake);
      }
    }
  };

  useEffect(() => {
    if (!isEmpty(user)) {
      myProfile();
    }
  }, [user]);

  const onSubmit1 = async (request) => {
    if (!isEmpty(mainStake) && mainStake.length > 4) {
      try {
        const { status, data: response_users } = await apiPost(
          apiPath.editStake,
          {
            editOneClickBetStake: mainStake,
          }
        );
        if (status === 200) {
          if (response_users.success) {
            setMainStake(response_users?.results?.stake);

            toast.success(response_users.message);
            setSetting(false);
            myProfile();
          } else {
            toast.error(response_users.message);
          }
        }
      } catch (err) {
        toast.error(err.response.data.message);
      }
    } else {
      toast.error("please select at least 5 stake");
    }
  };

  const setStakeShow = (value) => {
    let main = [...mainStake];
    const index = main.indexOf(value);
    if (mainStake.length < 6) {
      if (index === -1) {
        main.push(value);
      } else {
        main.splice(index, 1);
      }
    } else {
      if (index === -1) {
        main.sort((a, b) => a - b);
        main.shift();
        main.push(value);
      } else {
        main.splice(index, 1);
      }
    }

    setMainStake(main);
  };

  const handleStake = (e, i) => {
    let values = [...stakedata];
    values[i] = parseInt(e.target.value);
    setStakeData(values);
  };

  const handleOkClick = async (event) => {
    try {
      const { status, data: response_users } = await apiPost(
        apiPath.userEditStake,
        { editStake: stakedata }
      );
      if (status === 200) {
        if (response_users.success) {
        } else {
        }
      }
    } catch (err) {}

    stakedata.sort((a, b) => a - b);
    setOkShown(true);
    setEditShown(false);
  };

  return (
    <Modal show={setting} centered onHide={() => setSetting(false)}>
      <Modal.Body>
        <Form onSubmit={handleSubmit1(onSubmit1)}>
          <div className="stake-set p-2 pb-1">
            <div className="d-flex justify-content-between align-items-center mb-2">
              <strong style={{ fontSize: "18px" }}>Stake</strong>
              <IoCloseCircle
                style={{ cursor: "pointer" }}
                onClick={() => setSetting(false)}
                size={20}
              />
            </div>

            <div className="edit_wrap mb-1">
              {okShown && (
                <div className="d-flex justify-content-between" id="stakeSet">
                  <div className="d-flex flex-wrap">
                    {stakedata &&
                      stakedata.map((item, index) => {
                        return (
                          <div key={index + 1}>
                            <a
                              id={"coin_" + index + 1}
                              className={
                                mainStake.includes(item)
                                  ? "btn select-btn"
                                  : "btn"
                              }
                              onClick={(event) => setStakeShow(item)}
                            >
                              {item}
                            </a>
                          </div>
                        );
                      })}

                    <div>
                      <a
                        href="#"
                        className={"btn"}
                        style={{
                          background: "rgb(255, 184, 12)",
                          padding: "4px 10px",
                        }}
                        onClick={handleEditClick}
                      >
                        Edit
                      </a>
                    </div>
                  </div>
                </div>
              )}

              {editShown && (
                <div className="d-flex justify-content-between" id="stakeSet">
                  <div className="wrapper-p d-flex flex-wrap">
                    {stakedata &&
                      stakedata.map((item, index) => {
                        return (
                          <div key={index + 1}>
                            <Form.Control
                              type="text"
                              name="stake[]"
                              value={item}
                              className="stake_edit-input"
                              onChange={(e) => handleStake(e, index)}
                            />
                          </div>
                        );
                      })}
                  </div>
                  {/* <div className="col-edit border-0 d-block">
                    <a
                      href="#"
                      id="edit"
                      className="btn-edit ok-edit"
                      onClick={handleOkClick}
                    >
                      Ok
                    </a>
                  </div> */}

                  <div>
                    <a
                      href="#"
                      className={"btn"}
                      style={{
                        background: "rgb(255, 184, 12)",
                        padding: "4px 10px",
                      }}
                      onClick={handleOkClick}
                    >
                      Ok
                    </a>
                  </div>
                </div>
              )}
            </div>
          </div>

          <div
            style={{ display: "flex", alignItems: "center" }}
            className="btn-wrap p-2 pt-0"
          >
            <Button
              type="submit"
              style={{ marginRight: "10px" }}
              className="btn-send "
              onClick={() => setSetting(false)}
            >
              Close
            </Button>

            <Button
              type="submit"
              className="btn-send"
              disabled={editShown ? true : ""}
            >
              Save
            </Button>
          </div>
        </Form>
      </Modal.Body>
    </Modal>
  );
};

export default Setting;
