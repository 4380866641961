import { isEmpty, isNumber } from "lodash";
import React from "react";

const NewOddLay = ({
  odds_back,
  odds_lay,
  check,
  betSlipObject,
  item,
  showBetDialog,
  checkDisabled,
  handelChange,
  index,
  component,
  disabelBack,
  disabelLay,
  oldLay,
  oldBack,
  indexOdds,
  type,
}) => {
  const handelChangeBack = (item, index, indexOdds) => {
    return `position-relative bluBoxs  ${
      Number(
        document
          .getElementById(
            "back_odds_" + (item.SelectionId || item?.selectionID) + index
          )
          ?.title?.split("-")[0]
          ?.trim()
      ) !== odds_back[index]?.rt && "spark-back"
    }
        ${
          Number(
            document
              .getElementById(
                "back_odds_" + (item.SelectionId || item?.selectionID) + index
              )
              ?.title?.split("-")[1]
              ?.trim()
          ) !== odds_back[index]?.bv && "spark-back"
        } 
        ${
          betSlipObject?.teamName == item?.RunnerName &&
          betSlipObject?.odds == odds_back[index]?.rt &&
          betSlipObject?.betType == "back" &&
          showBetDialog
            ? "back-active"
            : ""
        }`;
  };
  const handelChangeLay = (item, index) => {
    return `position-relative redBoxs ${
      betSlipObject?.teamName == item?.RunnerName &&
      betSlipObject?.odds == odds_lay[index]?.rt &&
      betSlipObject?.betType == "lay" &&
      showBetDialog
        ? ""
        : //   "lay-active"
          ""
    } ${
      Number(
        document
          .getElementById(
            "lay_odds_" + (item.SelectionId || item?.selectionID) + index
          )
          ?.title?.split("-")[0]
          ?.trim()
      ) !== odds_lay[index]?.rt && "spark-lay"
    }
          ${
            Number(
              document
                .getElementById(
                  "lay_odds_" + (item.SelectionId || item?.selectionID) + index
                )
                ?.title?.split("-")[1]
                ?.trim()
            ) !== odds_lay[index]?.bv && "spark-lay"
          }
        }`;
  };

  return type == "back" ? (
    <div className="d-flex" style={{ cursor: "pointer", height: "100%" }}>
      {!isEmpty(odds_back[2]) ? (
        <div
          title={`${oldBack?.length > 0 && oldBack[2]?.rt}-${
            oldBack?.length > 0 && oldBack[2]?.bv
          }`}
          id={"back_odds_" + (item.SelectionId || item?.selectionID) + 2}
          onClick={() => {
            if (disabelBack) {
            } else {
              if (!checkDisabled(odds_back[2]?.rt)) {
                handelChange(
                  odds_back[2],
                  "back",
                  item?.RunnerName || item?.runnerName,
                  item,
                  index
                );
              }
            }
          }}
          className={handelChangeBack(item, 2)}
        >
          <div className={check(odds_back[2])}>
            {check(odds_back[2]) == "single-disabled" && (
              <span className="disabled"></span>
            )}
            <div style={{ fontWeight: "800", fontSize: "14px" }}>
              {odds_back[2]?.rt || ""}
            </div>
            <span>
              {" "}
              {component !== "bookmaker" ? odds_back[2]?.bv || "" : ""}
            </span>
          </div>
        </div>
      ) : (
        <div className="whiteBoxes">-</div>
      )}{" "}
      {!isEmpty(odds_back[1]) ? (
        <div
          title={`${oldBack?.length > 0 && oldBack[1]?.rt}-${
            oldBack?.length > 0 && oldBack[1]?.bv
          }`}
          id={"back_odds_" + (item.SelectionId || item?.selectionID) + index}
          onClick={() => {
            if (disabelBack) {
            } else {
              if (!checkDisabled(odds_back[1]?.rt)) {
                handelChange(
                  odds_back[1],
                  "back",
                  item?.RunnerName || item?.runnerName,
                  item,
                  index
                );
              }
            }
          }}
          className={handelChangeBack(item, 1)}
        >
          <div className={check(odds_back[1])}>
            {check(odds_back[1]) == "single-disabled" && (
              <span className="disabled"></span>
            )}
            <div style={{ fontWeight: "800", fontSize: "14px" }}>
              {odds_back[1]?.rt || ""}
            </div>
            <span>
              {" "}
              {component !== "bookmaker" ? odds_back[1]?.bv || "" : ""}
            </span>
          </div>
        </div>
      ) : (
        <div className="whiteBoxes">-</div>
      )}
      {!isEmpty(odds_back[0]) ? (
        <div
          title={`${oldBack?.length > 0 && oldBack[0]?.rt}-${
            oldBack?.length > 0 && oldBack[0]?.bv
          }`}
          id={"back_odds_" + (item.SelectionId || item?.selectionID) + index}
          onClick={() => {
            if (disabelBack) {
            } else {
              if (!checkDisabled(odds_back[0]?.rt)) {
                handelChange(
                  odds_back[0],
                  "back",
                  item?.RunnerName || item?.runnerName,
                  item,
                  index
                );
              }
            }
          }}
          className={handelChangeBack(item, 0)}
        >
          <div className={check(odds_back[0])}>
            {check(odds_back[0]) == "single-disabled" && (
              <span className="disabled"></span>
            )}
            <div style={{ fontWeight: "800", fontSize: "14px" }}>
              {odds_back[0]?.rt || ""}
            </div>
            <span>
              {" "}
              {component !== "bookmaker" ? odds_back[0]?.bv || "" : ""}
            </span>
          </div>
        </div>
      ) : (
        <div className="whiteBoxes">-</div>
      )}
    </div>
  ) : (
    <div className="d-flex" style={{ cursor: "pointer", height: "100%" }}>
      {!isEmpty(odds_lay[0]) ? (
        <div
          title={`${oldLay?.length > 0 && oldLay[0]?.rt}-${
            oldLay?.length > 0 && oldLay[0]?.bv
          }`}
          id={"lay_odds_" + (item.SelectionId || item?.selectionID) + index}
          onClick={() => {
            if (disabelLay) {
            } else {
              if (!checkDisabled(odds_lay[0]?.rt)) {
                handelChange(
                  odds_lay[0],
                  "lay",
                  item?.RunnerName || item?.runnerName,
                  item,
                  index
                );
              }
            }
          }}
          className={handelChangeLay(item, 0)}
        >
          <div className={check(odds_lay[0])}>
            {" "}
            {check(odds_lay[0]) == "single-disabled" && (
              <span className="disabled"></span>
            )}{" "}
            <div style={{ fontWeight: "800", fontSize: "14px" }}>
              {odds_lay[0]?.rt || ""}
            </div>
            <span>
              {component !== "bookmaker" ? odds_lay[0]?.bv || "" : ""}
            </span>
          </div>
        </div>
      ) : (
        <div className="whiteBoxes">-</div>
      )}{" "}
      {!isEmpty(odds_lay[1]) ? (
        <div
          title={`${oldLay?.length > 0 && oldLay[1]?.rt}-${
            oldLay?.length > 0 && oldLay[1]?.bv
          }`}
          id={"lay_odds_" + (item.SelectionId || item?.selectionID) + index}
          onClick={() => {
            if (disabelLay) {
            } else {
              if (!checkDisabled(odds_lay[1]?.rt)) {
                handelChange(
                  odds_lay[1],
                  "lay",
                  item?.RunnerName || item?.runnerName,
                  item,
                  index
                );
              }
            }
          }}
          className={handelChangeLay(item, 1)}
        >
          <div className={check(odds_lay[1])}>
            {" "}
            {check(odds_lay[1]) == "single-disabled" && (
              <span className="disabled"></span>
            )}{" "}
            <div style={{ fontWeight: "800", fontSize: "14px" }}>
              {odds_lay[1]?.rt || ""}
            </div>
            <span>
              {component !== "bookmaker" ? odds_lay[1]?.bv || "" : ""}
            </span>
          </div>
        </div>
      ) : (
        <div className="whiteBoxes">-</div>
      )}{" "}
      {!isEmpty(odds_lay[2]) ? (
        <div
          title={`${oldLay?.length > 0 && oldLay[2]?.rt}-${
            oldLay?.length > 0 && oldLay[2]?.bv
          }`}
          id={"lay_odds_" + (item.SelectionId || item?.selectionID) + index}
          onClick={() => {
            if (disabelLay) {
            } else {
              if (!checkDisabled(odds_lay[2]?.rt)) {
                handelChange(
                  odds_lay[2],
                  "lay",
                  item?.RunnerName || item?.runnerName,
                  item,
                  index
                );
              }
            }
          }}
          className={handelChangeLay(item, 2)}
        >
          <div className={check(odds_lay[2])}>
            {" "}
            {check(odds_lay[2]) == "single-disabled" && (
              <span className="disabled"></span>
            )}{" "}
            <div style={{ fontWeight: "800", fontSize: "14px" }}>
              {odds_lay[2]?.rt || ""}
            </div>
            <span>
              {component !== "bookmaker" ? odds_lay[2]?.bv || "" : ""}
            </span>
          </div>
        </div>
      ) : (
        <div className="whiteBoxes">-</div>
      )}
    </div>
  );
};

export default NewOddLay;
