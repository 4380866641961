import React, { useContext, useEffect, useRef, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import Cross from "../../img/cross.png";
import Rupee from "../../img/rupee.png";
import { inRange, isEmpty, isNumber } from "lodash";
import BetSlipContext from "../../context/BetSlipContext";
import { useNavigate, useParams } from "react-router-dom";
import useScroll from "../../component/Scroll";
import BackLay from "./BackLay";
import { useTranslation } from "react-i18next";
import { FaArrowRight } from "react-icons/fa";
import AuthContext from "../../context/AuthContext";
const Selection = ({
  name,
  bookmaker,
  runner,
  backOdds,
  layOdds,
  oldBack,
  oldLay,
  profileData,
  marketName,
  detail,
  ms,
  position,
  typeGame,
  setShowBetDialog,
  setLimit,
  setBetSlipObject,
  user,
  scrollHeight,
  marketId,
  setRightTab,
  BetSlip,
  title,
  totalMatched,
}) => {
  const { t } = useTranslation();
  const parmas = useParams();
  const myRef = useRef(null);
  const { defaultStakeLimit } = useContext(AuthContext);
  const [scrollId, setScrollId] = useScroll(myRef);
  const [localLimit, setLocalLimit] = useState({});
  const { showBetDialog, betSlipObject } = useContext(BetSlipContext);
  const naviagte = useNavigate();
  const handelChange = (odds, type, team, item, index) => {
    if (!isEmpty(user)) {
      if (odds?.rt > 0) {
        setRightTab("betslip");
        setScrollId(index);
        setShowBetDialog(true);
        setLimit(localLimit);
        setBetSlipObject((prev) => ({
          ...prev,
          odds: odds?.rt,
          teamName: team,
          betType: type,
          playType: typeGame,
          newMarketId: marketId,
          object: {
            ...item,
            ...odds,
            teamName: team,
            eventId: detail?.eventId,
            marketId: marketId,
            newMarketId: marketId,
          },
        }));
      }
    } else {
      naviagte("/login");
    }
  };

  const handelLimit = (marketName) => {
    let result = profileData?.matchSetting?.find((res) => {
      return res?.type == marketName;
    });
    if (!isEmpty(result)) {
      let obj = result;
      if (!isEmpty(obj)) {
        return obj;
      }
    } else {
      let obj = profileData?.matchSetting?.find(
        (res) =>
          res?.type == (typeGame == "bookmaker" ? "bookmaker" : "betFaire") &&
          (profileData?.arrayCheck == "details"
            ? true
            : res?.sportType == detail?.gameType)
      );

      if (!isEmpty(obj)) {
        if (marketName == "Match Odds") {
          if (!isEmpty(detail?.betLimitSetting)) {
            return !isEmpty(obj)
              ? {
                  ...obj,
                  minAmount: detail?.betLimitSetting?.minBet,
                  maxAmount: detail?.betLimitSetting?.maxBet,
                }
              : {};
          } else {
            return !isEmpty(obj) ? obj : {};
          }
        } else {
          return obj;
        }
      } else {
        return {
          maxAmount: 500,
          maxProfit: 10000,
          minAmount: 1,
          oddsLimit: 20,
        };
      }
    }
  };
  useEffect(() => {
    let limit = handelLimit(marketName);
    let newLimit = defaultStakeLimit?.find((res) => {
      return (
        res?.betfairId == detail.eventType &&
        res?.market == marketName &&
        inRange(totalMatched, res?.min, res?.max)
      );
    });
    if (isEmpty(newLimit)) {
      setLocalLimit(limit);
    } else {
      setLocalLimit({ ...limit, maxAmount: newLimit?.stake });
    }
  }, [marketName, totalMatched, defaultStakeLimit]);

  const format = (number) => {
    var nf = new Intl.NumberFormat();
    return nf.format(number);
  };

  return (
    <div data-aos="zoom-in-up" className="leagueBox ">
      {totalMatched > 0 && (
        <div className="d-flex mb-1 justify-content-end align-items-center">
          <span>
            Matched{" "}
            <span style={{ fontWeight: "600", marginLeft: "3px" }}>
              INR {format(totalMatched) || 0}
            </span>
          </span>
        </div>
      )}
      <div className="title" style={{ borderRadius: "0" }}>
        <Row>
          <Col style={{ fontWeight: "600" }} md={6}>
            {title}
          </Col>

          <Col md={6} className="text-dark text-center">
            <Row style={{ fontWeight: "600" }}>
              <Col className="text-center text-white" md={4}>
                {t("Back")}
              </Col>{" "}
              <Col className="text-center text-white" md={4}></Col>
              <Col className="text-center text-white" md={4}>
                {t("Lay")}
              </Col>
            </Row>
          </Col>
        </Row>
      </div>
      <div className="laegueRow position-relative" style={{ padding: "0 8px" }}>
        {runner?.map((item, index) => {
          const odds_back = backOdds?.filter((todd) =>
            typeGame == "bookmaker"
              ? todd?.runnerName?.toLowerCase() ==
                (item?.RunnerName?.toLowerCase() ||
                  item?.runnerName?.toLowerCase())
              : todd.ri === item.SelectionId
          );
          const odds_lay = layOdds?.filter((todd) =>
            typeGame == "bookmaker"
              ? todd?.runnerName?.toLowerCase() ==
                (item?.RunnerName?.toLowerCase() ||
                  item?.runnerName?.toLowerCase())
              : todd.ri === item.SelectionId
          );
          const old_back = oldBack?.filter((todd) =>
            typeGame == "bookmaker"
              ? todd?.runnerName?.toLowerCase() ==
                (item?.RunnerName?.toLowerCase() ||
                  item?.runnerName?.toLowerCase())
              : todd.ri === item.SelectionId
          );
          const old_lay = oldLay?.filter((todd) =>
            typeGame == "bookmaker"
              ? todd?.runnerName?.toLowerCase() ==
                (item?.RunnerName?.toLowerCase() ||
                  item?.runnerName?.toLowerCase())
              : todd.ri === item.SelectionId
          );
          let disabelBack = "lockBackBets" in item ? item?.lockBackBets : false;
          let disabelLay = "lockLayBets" in item ? item?.lockLayBets : false;

          const odds_lay2 =
            layOdds?.find((todd) =>
              typeGame == "bookmaker"
                ? todd?.runnerName?.toLowerCase() ==
                  (item?.RunnerName?.toLowerCase() ||
                    item?.runnerName?.toLowerCase())
                : todd.ri === item.SelectionId
            ) || {};
          let odds_back2 =
            backOdds?.find((todd) =>
              typeGame == "bookmaker"
                ? todd?.runnerName?.toLowerCase() ==
                  (item?.RunnerName?.toLowerCase() ||
                    item?.runnerName?.toLowerCase())
                : todd.ri === item.SelectionId
            ) || {};
          let layRt = odds_lay2?.rt > 0 ? odds_lay2?.rt : 0;
          let backRt = odds_back2?.rt > 0 ? odds_back2?.rt : 0;
          let disable = false;
          if (odds_lay?.length == 0 && odds_back?.length == 0) {
            disable = true;
          } else if (layRt <= 0 && backRt <= 0) {
            disable = true;
          } else if (
            (layRt > localLimit?.oddsLimit || layRt == 0) &&
            (backRt > localLimit?.oddsLimit || backRt == 0)
          ) {
            disable = true;
          } else {
            disable = false;
          }
          return (
            <div
              style={
                runner?.length - 1 !== index
                  ? { borderBottom: "1px solid #ddd" }
                  : {}
              }
              className="laegueRow position-relative"
            >
              <Row className="position-relative">
                <Col md={6} className={"text-black selection-slip"}>
                  <span style={{ fontSize: "15px" }}>
                    {item?.RunnerName || item?.runnerName}
                  </span>
                  {(Math.abs(item?.position) > 0 ||
                    Math.abs(item?.newPosition)) ? (
                    <span
                      style={{
                        display: "flex",
                        alignItems: "center",
                        fontSize: "13px",
                        marginLeft: "10px",
                      }}
                      className={` ${
                        item?.position > 0
                          ? "text-green team-red-score"
                          : "team-red-score"
                      }`}
                    >
                      {isNumber(item?.position) && Math.abs(item?.position) > 0
                        ? item?.position > 0
                          ? Math?.abs(item?.position).toFixed(2)
                          : `( ${Math.abs(item?.position).toFixed(2)} )`
                        : ""}
                      {Math.abs(item?.newPosition) >= 0 &&
                        showBetDialog &&
                        betSlipObject?.playType == typeGame &&
                        marketId == betSlipObject?.object?.newMarketId && (
                          <span
                            style={{
                              display: "flex",
                              alignItems: "center",
                              marginLeft: "5px",
                              fontSize: "13px",
                            }}
                            className={
                              item?.newPosition > 0
                                ? "text-green"
                                : "team-red-score"
                            }
                          >
                            <FaArrowRight
                              color={item?.newPosition > 0 ? "green" : "red"}
                              style={{ marginRight: "3px" }}
                            />
                            {item?.newPosition > 0
                              ? Math.abs(item?.newPosition).toFixed(2)
                              : `( ${Math.abs(item?.newPosition).toFixed(2)} )`}
                          </span>
                        )}
                    </span>
                  )  :''}
                </Col>
                <BackLay
                  component={typeGame}
                  index={index}
                  marketName={marketName}
                  item={item}
                  disabelBack={disabelBack}
                  oldBack={old_back}
                  oldLay={old_lay}
                  conditionNew={disable && ms == 1}
                  disabelLay={disabelLay}
                  handelChange={handelChange}
                  isInPlay={detail?.status == "in_play"}
                  odds_lay={odds_lay}
                  odds_back={odds_back}
                  limit={localLimit}
                  ms={ms}
                />
              </Row>{" "}
              {name == "Bookmaker" &&
                betSlipObject?.teamName ==
                  (item?.RunnerName || item?.runnerName) &&
                !isEmpty(betSlipObject?.betType) &&
                betSlipObject?.playType == "bookmaker" && <BetSlip />}
            </div>
          );
        })}
        {detail?.market !== 'Winner' && detail?.status !== "in_play" && name !== "Bookmaker" ? (
          <div className="overlay-match-outer">{t("Suspended")}</div>
        ) : (
          ms != 1 && <div className="overlay-match-outer">{t("Suspended")}</div>
        )}
      </div>
      {!isEmpty(localLimit) && (
        <div
          style={{
            display: "flex",
            justifyContent: "flex-end",
            color: "black",
            fontSize: "12px",
            marginBottom: "4px",
          }}
        >
          {t("Min")} :{" "}
          <span style={{ fontWeight: "600", margin: "0 3px" }}>
            {format(localLimit?.minAmount)}
          </span>{" "}
          | {t("Max")} :{" "}
          <span style={{ fontWeight: "600", margin: "0 3px" }}>
            {format(localLimit?.maxAmount)}
          </span>
        </div>
      )}
    </div>
  );
};

export default Selection;
