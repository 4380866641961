import { render, screen } from "@testing-library/react";
import React, { useContext, useEffect, useRef, useState } from "react";

import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";

import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import { IoIosArrowBack } from "react-icons/io";
import loginImg from "../img/login-img.png";
import logo from "../img/header-logo.png";
import { Link, useNavigate } from "react-router-dom";
import AuthContext from "../context/AuthContext";
import { isEmpty, pick } from "lodash";
import { useForm } from "react-hook-form";
import jwt_decode from "jwt-decode";
import {
  FaUserAlt,
  FaLock,
  FaShieldAlt,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";
import { apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import { toast } from "react-toastify";
function ChangePassProfile() {
  const { logoutUser, user, setUser } = useContext(AuthContext);
  const navigate = useNavigate();

  const handleShow = (id) => {
    setShowPassword({ ...showPassword, [id]: !showPassword[id] });
  };

  const [showPassword, setShowPassword] = useState({
    first: true,
    second: true,
    third: true,
  });

  const {
    register,
    handleSubmit,
    formState: { errors },
    unregister,
    reset,
    watch,
    trigger,
    setValue,
  } = useForm({});

  useEffect(() => {
    if (!isEmpty(watch("confirmPassword"))) {
      if (watch("newPassword")) {
        trigger("confirmPassword");
      }
    }
  }, [watch("newPassword")]);

  const onSubmit = async (body) => {
    const obj = pick(body, ["oldPassword", "newPassword"]);
    const { status, data } = await apiPost(apiPath.changePassword, obj);
    if (status === 200) {
      if (data.success) {
        localStorage.removeItem("token");
        localStorage.removeItem("refresh_token");
        // setSuccess({ check: true, message: data?.message });
        localStorage.setItem("token", data.results.token);
        localStorage.setItem("refresh_token", data.results.refresh_token);
        setUser(jwt_decode(data.results.token));
        navigate("/");
      } else {
        toast.error(data?.message);
      }
    } else {
      toast.error(data?.message);
    }
  };
  const preventMaxInput = (e) => {
    e.target.value = e.target.value.trimStart();
    e.target.value = e.target.value.replace(/  +/g, " ");
  };
  return (
    <>
      <div className="login">
        <div
          style={{ top: "1%", left: "1%" }}
          className="d-flex position-absolute align-items-center justify-content-start"
        >
          <div
            className="left-arrow-icon d-flex justify-content-center align-items-center"
            style={{
              background: "white",
              borderRadius: "50%",
              marginLeft: "8px",
              marginTop: "8px",
            }}
            onClick={() => navigate("/")}
          >
            <IoIosArrowBack size={30} className="fs-1 p-1 cursor-pointer" />
          </div>
        </div>
        <Container className="h-100 align-middle">
          <Row className="h-100 align-items-center align-middle justify-content-center">
            <Col md={11}>
              <Row>
                <Col md={7} className="text-start">
                  <img
                    data-aos="zoom-in-down"
                    src={loginImg}
                    style={{ width: "80%" }}
                  />
                </Col>
                <Col md={5}>
                  <div data-aos="flip-up" className="text-center mb-4">
                    <img src={logo} style={{ width: "220px" }} />
                  </div>
                  <Form onSubmit={handleSubmit(onSubmit)}>
                    <div data-aos="zoom-in" className="loginForm">
                      <div className="d-flex flex-column align-items-center mb-4">
                        <h5 className="m-0">Change Password</h5>
                      </div>
                      <Form.Label>Old Password</Form.Label>
                      <Form.Group className="mb-2 position-relative">
                        <Form.Control
                          type={!showPassword?.first ? "text" : "password"}
                          placeholder="Old Password"
                          maxLength={16}
                          minLength={8}
                          onInput={(e) => preventMaxInput(e)}
                          {...register("oldPassword", {
                            required: "Please enter old password.",
                            validate: (value) => {
                              if (value === "") {
                                return true;
                              }
                              if (!!value.trim()) {
                                return true;
                              } else {
                                return "White space are not allowed";
                              }
                            },
                          })}
                        />
                        <button
                          className=" w-25 position-absolute translate-middle-y top-50 end-0 me-2 bg-transparent border-0 text-dark p-0 "
                          onClick={() => handleShow("first")}
                        >
                          {!showPassword?.first ? (
                            <FaEye className="opacity-100 fs-5" size="17" />
                          ) : (
                            <FaEyeSlash
                              className="opacity-100 fs-5"
                              size="17"
                            />
                          )}
                        </button>
                        {errors.oldPassword && errors.oldPassword.message && (
                          <label className="invalid-feedback text-left">
                            {errors.oldPassword.message}
                          </label>
                        )}
                      </Form.Group>
                      <Form.Label>New Password</Form.Label>
                      <Form.Group className="mb-2 position-relative">
                        <Form.Control
                          type={!showPassword?.second ? "text" : "password"}
                          placeholder="New Password"
                          maxLength={16}
                          minLength={8}
                          onInput={(e) => preventMaxInput(e)}
                          {...register("newPassword", {
                            required: "Please enter new password.",
                            validate: (value) => {
                              if (value === "") {
                                return true;
                              }
                              if (!!value.trim()) {
                                return true;
                              } else {
                                return "White spaces not allowed.";
                              }
                            },
                            pattern: {
                              value:
                                /^(?=.*?[A-Z])(?=.*?[a-z])(?=.*?[0-9])(?=.*?[#?!@$%^&*-]).{8,15}$/,
                              message:
                                "New Password must contain lowercase,uppercase characters, numbers, special character and must be 8 character long.",
                            },
                          })}
                        />
                        <button
                          className=" w-25 position-absolute translate-middle-y top-50 end-0 me-2 bg-transparent border-0 text-dark p-0 "
                          onClick={() => handleShow("second")}
                        >
                          {!showPassword?.second ? (
                            <FaEye className="opacity-100 fs-5" size="17" />
                          ) : (
                            <FaEyeSlash
                              className="opacity-100 fs-5"
                              size="17"
                            />
                          )}
                        </button>
                        {errors.oldPassword && errors.oldPassword.message && (
                          <label className="invalid-feedback text-left">
                            {errors.oldPassword.message}
                          </label>
                        )}
                      </Form.Group>

                      <Form.Label>Confirm Password</Form.Label>
                      <Form.Group className="mb-2 position-relative">
                        <Form.Control
                          type={!showPassword?.third ? "text" : "password"}
                          placeholder="Confirm Password"
                          onInput={(e) => preventMaxInput(e)}
                          maxLength={16}
                          minLength={8}
                          {...register("confirmPassword", {
                            required: {
                              value: true,
                              message: "Please enter confirm password.",
                            },
                            validate: (value) => {
                              if (value === "") {
                                return true;
                              }
                              if (!isEmpty(watch("newPassword"))) {
                                if (value == watch("newPassword")) {
                                  return true;
                                } else {
                                  return "Password and confirm password does not match.";
                                }
                              }
                            },
                          })}
                          onChange={(e) => {
                            setValue("confirmPassword", e.target.value, {
                              shouldValidate: true,
                            });
                          }}
                        />
                        <button
                          className=" w-25 position-absolute translate-middle-y top-50 end-0 me-2 bg-transparent border-0 text-dark p-0 "
                          onClick={() => handleShow("third")}
                        >
                          {!showPassword?.third ? (
                            <FaEye className="opacity-100 fs-5" size="17" />
                          ) : (
                            <FaEyeSlash
                              className="opacity-100 fs-5"
                              size="17"
                            />
                          )}
                        </button>
                        {errors.confirmPassword &&
                          errors.confirmPassword.message && (
                            <label className="invalid-feedback text-left">
                              {errors.confirmPassword.message}
                            </label>
                          )}
                      </Form.Group>

                      <Button type="submit" variant="primary" className="mt-1">
                        Change
                      </Button>
                      <ul class="list-outside p-0 m-0 mt-4 pe-0 px-4">
                        <li>
                          Password must have 8 to 15 alphanumeric without white
                          space
                        </li>
                        <li>
                          Password cannot be the same as username / nickname
                        </li>
                        <li>
                          Must contain at least 1 capital letter, 1 small letter
                          and 1 number
                        </li>
                        <li>
                          Password must not contain any special characters
                          (!,@,#,etc..)
                        </li>
                      </ul>
                    </div>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default ChangePassProfile;
