import { render, screen } from "@testing-library/react";
import React, { useContext, useEffect, useState, useRef } from "react";

import "../App.css";
import "bootstrap/dist/css/bootstrap.min.css";
import "bootstrap/dist/js/bootstrap.bundle.min";
import { FloatingLabel } from "react-bootstrap";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Form from "react-bootstrap/Form";
import Button from "react-bootstrap/Button";
import PhoneInput from "react-phone-input-2";
import "react-phone-input-2/lib/style.css";
import loginImg from "../img/login-img.png";
import logo from "../img/header-logo.png";
import { IoIosArrowBack } from "react-icons/io";
import {
  Link,
  useNavigate,
  useLocation,
  useNavigation,
} from "react-router-dom";

import AuthContext from "../context/AuthContext";
import { inRange, isEmpty } from "lodash";
import { Controller, useForm } from "react-hook-form";
import {
  FaUserAlt,
  FaLock,
  FaShieldAlt,
  FaEye,
  FaEyeSlash,
} from "react-icons/fa";
import { useTranslation } from "react-i18next";
import { apiPost } from "../Utils/apiFetch";
import apiPath from "../Utils/apiPath";
import { toast } from "react-toastify";
function Register() {

  const { t } = useTranslation();
  let { loginUser, user } = useContext(AuthContext);
  const { registerUser } = useContext(AuthContext);
  const [showPassword, setShowPassword] = useState(true);
  const [number, setNumber] = useState("");
  const [showPasswordConfirm, setShowPasswordConfirm] = useState(true);
  const handleShow = () => {
    setShowPassword(!showPassword);
  };
  const handleShowConfirm = () => {
    setShowPasswordConfirm(!showPasswordConfirm);
  };
  const navigate = useNavigate();
  const inputRef = useRef(null);
  const {
    register,
    unregister,
    handleSubmit,
    control,
    setValue,
    watch,
    formState: { errors },
  } = useForm({
    mode: "onBlur",
    shouldFocusError: true,
    defaultValues: {
      countryCode: 91,
      uniqueId: Math.random() * 10000,
    },
  });
  const [step, setStep] = useState(0);
  const { search } = useLocation();
  let code = search?.split("=")[1];
  let codeCondition = search?.split("=")?.includes("?referral_code")
    ? true
    : false;

  useEffect(() => {
    if (codeCondition) {
      setValue("refreralCode", code);
    }
  }, [code, codeCondition]);

  // const [isLoader, setLoader] = useState(false);
  const onSubmit = async (body) => {
    if (parseInt(body.validateCode) === parseInt(getValidateCode)) {
      registerUser({ ...body, number: number });
    }
  };

  const [getValidateCode, setValidateCode] = useState("");
  const canvasRef = useRef(null);
  const changeCode = () => {
    const canvas = canvasRef.current;
    const context = canvas.getContext("2d");
    context.font = "bold 120px sans-serif";
    const code = Math.floor(1000 + Math.random() * 9000);
    context.clearRect(0, 0, canvas.width, canvas.height);
    context.fillText(code, 0, 130);
    setValidateCode(code);
  };
  useEffect(() => {
    if (step == 1) {
      changeCode();
    }
  }, [step]);
  const [otpSend, setOtpSend] = useState(false);
  const userSendOtp = async (value) => {
    const { status, data } = await apiPost(apiPath.sendOtp, {
      phone_number: value,
    });
    if (status == 200) {
      if (data?.success) {
        setOtpSend(true);
        if (!isEmpty(data?.results?.otp)) {
          setValue("verificationCode", data?.results?.otp);
        }
      } else {
        toast.error(data?.message);
      }
    }
  };

  const onSubmitOTP = async (body) => {
    const { status, data } = await apiPost(apiPath.verifyOtp, {
      phone_number:
        inputRef?.current?.state.selectedCountry?.countryCode +
        body?.mobile?.substring(
          inputRef?.current?.state.selectedCountry?.countryCode?.length,
          body?.mobile?.toString()?.length
        ),
      otp: body?.verificationCode,
    });
    if (status === 200) {
      if (data.success) {
        setNumber(
          inputRef?.current?.state.selectedCountry?.countryCode +
            body?.mobile?.substring(
              inputRef?.current?.state.selectedCountry?.countryCode?.length,
              body?.mobile?.toString()?.length
            )
        );
        setStep(1);
      } else {
        toast.error(data?.message);
      }
    } else {
      toast.error(data?.message);
    }
  };
  return (
    <>
      <div className="login">
        <div
          style={{ top: "1%", left: "1%" }}
          className="d-flex position-absolute align-items-center justify-content-start"
        >
          <div
            className="left-arrow-icon d-flex justify-content-center align-items-center"
            style={{
              background: "white",
              borderRadius: "50%",
              marginLeft: "8px",
              marginTop: "8px",
            }}
            onClick={() => navigate("/")}
          >
            <IoIosArrowBack size={30} className="fs-1 p-1 cursor-pointer" />
          </div>
        </div>
        <Container className="h-100 align-middle">
          <Row className="h-100 align-items-center align-middle justify-content-center">
            <Col md={11}>
              <Row>
                <Col md={7} className="text-start">
                  <img
                    data-aos="zoom-in-down"
                    src={loginImg}
                    style={{ width: "90%" }}
                  />
                </Col>
                <Col md={5}>
                  <div data-aos="flip-up" className="text-center mt-5 mb-4">
                    <img src={logo} style={{ maxWidth: "200px" }} />
                  </div>
                  <Form
                    onSubmit={handleSubmit(step == 0 ? onSubmitOTP : onSubmit)}
                  >
                    <div data-aos="zoom-in" className="loginForm">
                      {" "}
                      <div className="d-flex justify-content-center text-black flex-column align-items-center mb-2">
                        {" "}
                        <h4>{t("Registration")}</h4>
                        <span
                          style={{ fontSize: "13px", marginBottom: "10px" }}
                        >
                          Step-1, Verify your mobile to continue
                        </span>
                      </div>
                      {step == 0 && (
                        <>
                          <Form.Label>Phone</Form.Label>
                          <FloatingLabel controlId="floatingInput" label="">
                            <Controller
                              control={control}
                              name="mobile"
                              rules={{
                                required: t("Enter_Mobile"),
                                validate: (value) => {
                                  let inputValue = value
                                    ?.toString()
                                    ?.slice(
                                      inputRef?.current?.state?.selectedCountry
                                        ?.countryCode?.length,
                                      value?.length
                                    );
                                  if (inputValue?.length < 7) {
                                    return t("MobileMin");
                                  } else if (inputValue?.length > 12) {
                                    return t("MobileMax");
                                  } else {
                                    return true;
                                  }
                                },
                              }}
                              render={({ field: { ref, ...field } }) => (
                                <>
                                  <PhoneInput
                                    {...field}
                                    inputExtraProps={{
                                      ref,
                                      required: true,
                                      autoFocus: true,
                                    }}
                                    ref={inputRef}
                                    inputStyle={{
                                      width: "100%",
                                      height: "38px",
                                    }}
                                    // countryCodeEditable={false}
                                    // disableDropdown={true}
                                    country={"in"}
                                    onlyCountries={["in", "gb", "ae", "au"]}
                                  />
                                </>
                              )}
                            />
                          </FloatingLabel>
                          {errors?.mobile?.message && (
                            <label className="invalid-feedback text-left">
                              {errors?.mobile?.message}
                            </label>
                          )}
                          <div className="d-flex flex-column align-items-start">
                            <span
                              style={{ fontSize: "12px", marginTop: "4px" }}
                            >
                              Recive OTP via Whatsapp
                            </span>
                            <Button
                              type="button"
                              // disabled={otpSend}
                              onClick={() => {
                                if (!isEmpty(watch("mobile"))) {
                                  let value = watch("mobile")
                                    ?.toString()
                                    ?.slice(
                                      inputRef?.current?.state?.selectedCountry
                                        ?.countryCode?.length,
                                      watch("mobile")?.length
                                    );
                                  if (inRange(value?.length, 8, 12)) {
                                    userSendOtp(watch("mobile"));
                                  } else {
                                    toast.error(
                                      "Mobile number must contain 9 digit"
                                    );
                                  }
                                } else {
                                  if (isEmpty(watch("mobile"))) {
                                    toast.error("Please enter mobile number");
                                  }
                                }
                              }}
                              style={{
                                color: "black",
                                backgroundImage:
                                  "linear-gradient(rgb(255, 230, 157), rgb(175, 153, 85))",
                                border: "none",
                                width:"40%"
                              }}
                              className="theme-btn py-1 px-3 fs-6 mb-2"
                            >
                              {otpSend ? "Resend OTP" : "Get OTP"}
                            </Button>{" "}
                          </div>
                          <Form.Label className="mt-2">OTP</Form.Label>
                          <Form.Group className="position-relative mb-2">
                            <Form.Control
                              type="text"
                              placeholder="Enter OTP"
                              maxlength="6"
                              className={
                                errors.verificationCode ? " is-invalid " : ""
                              }
                              {...register("verificationCode", {
                                required: "Please enter OTP",
                                pattern: {
                                  value: /^[0-9]+$/,
                                  message: "Please enter a valid number",
                                },
                              })}
                            />

                            {errors.verificationCode &&
                              errors?.verificationCode?.message && (
                                <label className="text-danger">
                                  {errors.verificationCode.message}
                                </label>
                              )}
                          </Form.Group>
                          <div className="text-center mt-3">
                            <Button
                              style={{
                                fontSize: "15px",
                                backgroundImage:
                                  "linear-gradient(rgb(255, 230, 157), rgb(175, 153, 85))",
                                border: "none",
                                width: "100%",
                              }}
                              type="submit"
                              className="theme-btn"
                            >
                              Verify
                            </Button>
                          </div>
                        </>
                      )}
                      {step == 1 && (
                        <>
                          <Form.Label>{t("User_Name")}</Form.Label>{" "}
                          <Form.Group className="mb-2">
                            <Form.Control
                              {...register("username", {
                                required: t("Enter_Username"),
                                minLength: {
                                  value: 3,
                                  message: t("Min3User"),
                                },
                                maxLength: {
                                  value: 30,
                                  message: t("Max30User"),
                                },
                                pattern: {
                                  value: /^[\w-_.]{0,10}$/,
                                  message: t("Enter_Valid_Username"),
                                },
                              })}
                              autocomplete="off"
                              type="text"
                              placeholder={t("User_Name")}
                            />{" "}
                            {errors.username && errors.username.message && (
                              <label className="invalid-feedback text-left">
                                {errors.username.message}
                              </label>
                            )}
                          </Form.Group>
                          <Form.Label>{t("Password")}</Form.Label>
                          <Form.Group className="mb-2 position-relative">
                            <Form.Control
                              type={!showPassword ? "text" : "password"}
                              placeholder={t("Password")}
                              maxLength={15}
                              {...register("password", {
                                required: t("Enter_Password"),
                                minLength: {
                                  value: 8,
                                  message: t("PasswordMin"),
                                },
                                maxLength: {
                                  value: 16,
                                  message: t("PasswordMax"),
                                },
                              })}
                            />
                            <button
                              className=" w-25 position-absolute translate-middle-y top-50 end-0 me-2 bg-transparent border-0 text-dark p-0 "
                              onClick={() => handleShow()}
                            >
                              {!showPassword ? (
                                <FaEye className="opacity-100 fs-5" size="17" />
                              ) : (
                                <FaEyeSlash
                                  className="opacity-100 fs-5"
                                  size="17"
                                />
                              )}
                            </button>
                          </Form.Group>{" "}
                          {errors.password && errors?.password?.message && (
                            <label className="invalid-feedback text-left">
                              {errors.password.message}
                            </label>
                          )}
                          <Form.Label>{t("Confirm_Password")}</Form.Label>
                          <Form.Group className="mb-2 position-relative">
                            <Form.Control
                              type={!showPasswordConfirm ? "text" : "password"}
                              placeholder={t("Confirm_Password")}
                              maxLength={15}
                              {...register("confirmPassword", {
                                required: t("Enter_CPassword"),
                                validate: (value) => {
                                  if (!isEmpty(watch("password"))) {
                                    if (value == watch("password")) {
                                      return true;
                                    } else {
                                      return t("PasswordMatch");
                                    }
                                  }
                                },
                              })}
                            />
                            <button
                              className=" w-25 position-absolute translate-middle-y top-50 end-0 me-2 bg-transparent border-0 text-dark p-0 "
                              onClick={() => handleShowConfirm()}
                            >
                              {!showPasswordConfirm ? (
                                <FaEye className="opacity-100 fs-5" size="17" />
                              ) : (
                                <FaEyeSlash
                                  className="opacity-100 fs-5"
                                  size="17"
                                />
                              )}
                            </button>
                          </Form.Group>{" "}
                          {errors.confirmPassword &&
                            errors?.confirmPassword?.message && (
                              <label className="invalid-feedback text-left">
                                {errors.confirmPassword.message}
                              </label>
                            )}
                          <Form.Label>{t("ValidationCode")}</Form.Label>
                          <Form.Group className="position-relative mb-2">
                            <Form.Control
                              type="number"
                              keyboardType="numeric"
                              autocomplete="off"
                              maxLength="4"
                              {...register("validateCode", {
                                required: t("Enter_Vadlidation"),
                                validate: {
                                  validate: (value) =>
                                    parseInt(value) ===
                                      parseInt(getValidateCode) ||
                                    t("Invalid_Code"),
                                },
                              })}
                              onChange={(e) => {
                                if (e.target.value.length == 4) {
                                  e.target.blur();
                                  unregister("validateCode", {
                                    keepValue: true,
                                  });
                                }
                              }}
                              placeholder={t("ValidationCode")}
                            />{" "}
                            <canvas
                              ref={canvasRef}
                              onClick={changeCode}
                              className="position-absolute translate-middle-y top-50 canvas-img"
                              id="authenticateImage"
                            />
                          </Form.Group>
                          {errors.validateCode &&
                            errors?.validateCode?.message && (
                              <label className="invalid-feedback text-left">
                                {errors.validateCode.message}
                              </label>
                            )}
                          <Form.Label>{t("Agent_Code")}</Form.Label>
                          <Form.Control
                            {...register("refreralCode", {
                              // required: "Please enter agent code",
                            })}
                            autocomplete="off"
                            type="text"
                            placeholder={t("Agent_Code")}
                          />
                          {/* <p className="f-password">
                        {" "}
                        <Link
                          to="/ForgotPassword"
                          style={{ color: "white", textDecoration: "none" }}
                        >
                          Forgot Password?
                        </Link>
                      </p> */}
                          <Button
                            type="submit"
                            variant="primary"
                            className="mt-3"
                          >
                            {t("Confirm")}
                          </Button>
                        </>
                      )}
                    </div>
                  </Form>
                </Col>
              </Row>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default Register;
